import React from 'react'

export default function FAQ() {
  return (
    <div className='dark:bg-gray-800 dark:text-gray-200 h-[calc(100vh-64px-235px)]'>
      <div className="m-20">
          <h1 className="text-3xl my-10">
              <p>FAQ</p>
          </h1>
          <h2 className="text-2xl">
              
              <a className="hover:underline" href="" alt="">
                  <li className="list-none p-3 border-b-2 border-gray-500 cursor-pointer">
                      How to lose?
                  </li>
              </a>

          </h2>
      </div>
    </div>
  )
}
