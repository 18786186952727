import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function SellingPath() {
    const navigate = useNavigate();

    const handleTabChange = (question) => {
        navigate(question);
    }
    
  return (
    <div className='flex flex-row gap-2 m-20 mb-0'>
        <button className='text-teal-500 hover:text-teal-600 text-lg' onClick={() => handleTabChange('/Help-center')}>Help Center</button>
        <p className='text-lg'> ➡️ </p>
        <button className='text-teal-500 hover:text-teal-600 text-lg' onClick={() => handleTabChange('/Help-center/Buying')}>Buying</button>
        <p className='text-lg'> ➡️ </p>
    </div>
  )
}