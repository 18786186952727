import React from "react";

function Intro2() {
    return (
        <div className="bg-white dark:bg-gray-800 py-6 sm:py-8 lg:py-12">
            <div className="max-w-screen-2xl px-4 md:px-8 mx-auto">
                <div className="grid md:grid-cols-2 gap-16 lg:gap-24">
                    <div>
                        <div className="w-128 md:w-auto flex flex-col justify-center">
                            <img src="images/sellNfts11.png" loading="lazy" alt="Sell nft" className="w-full h-full object-cover object-center" />
                        </div>
                    </div>
                    <div className="flex flex-col justify-center">
                        <p className="text-teal-500 font-bold text-center md:text-left">Generous Community</p>
                        <h1 className="text-gray-800 dark:text-gray-200 text-2xl sm:text-3xl font-bold text-center md:text-left mb-4 md:mb-6">Gift or sell the ownership of your played games</h1>
                        {/* <p className="text-gray-500 sm:text-lg mb-6 md:mb-8">
                            ......
                        </p> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Intro2;
