import React from 'react'
import GettingStartedPath from '../components/GettingStartedPath';
import GettingStartedSideMenu from '../components/GettingStartedSideMenu';

export default function What_are_service_fees_and_royalties() {

    return (
        <div className='flex dark:bg-gray-800 dark:text-gray-200'>
            
            <GettingStartedSideMenu/>

            <div className='flex flex-col'>
            
                <GettingStartedPath/>

                <div className="m-20 mt-0 inline-block text-lg">

                    <div className='mb-10'>
                        <h1 className="text-4xl mt-10 mb-10">
                            <p>What are service fees and royalties?</p>
                        </h1>

                        <h3 className='text-3xl border-b'>
                            <p>Service fees</p>
                        </h3>

                        <p className="my-8">
                            Service fees are fees charged by a marketplace for their services on transactions.
                        </p>

                        <p>On 6uo Gameplace, we charged on buyer 2.5% for each transaction.</p>
                    </div>
                    
                    <div className='mb-10'>
                        <h3 className='text-3xl border-b'>
                            <p>Royalties</p>
                        </h3>

                        <p className="my-8">
                            Creator royalties are payments associated with the selling of NFTs that are normally set between 5% and 10% of the sale price and are paid to the creators of a certain NFT project by the seller.
                        </p>

                        <p>To encourage creators to continue updating their games, royalties for 6uo Gameplace games would be set at 10% each transaction.</p>
                    </div>

                </div>
        
            </div>
        </div>
    )
}
