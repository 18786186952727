import React from 'react'
import GettingStartedPath from '../components/GettingStartedPath';
import GettingStartedSideMenu from '../components/GettingStartedSideMenu';

export default function How_to_create_a_6uo_Gameplace_account() {

    return (
        <div className='flex dark:bg-gray-800 dark:text-gray-200'>

            
            <GettingStartedSideMenu/>

            <div className='flex flex-col'>

            <GettingStartedPath/>

            <div className="m-20 mt-0 inline-block text-lg">

                <h1 className="text-4xl mt-10 mb-10">
                    <p>How do I create a 6uo Gameplace account?</p>
                </h1>

                <p className="my-8">
                    To fully access 6uo NFT game place's services, you need to download and register a crypto wallet. After you have created a crypto wallet:
                </p>

                <p>
                    &emsp; 1. Head to gp.6uogames.com and click wallet icon located in the upper right hand corner of the screen.
                </p>

                <p><img className="my-20 width-full mx-auto" src="/HelpCenter/how_to_create_a_6uo_NFT_account/6uoGamePlace_nav_bar.png"></img></p>

                <p>
                    &emsp; 2. Select your wallet provider. For example, we will be using MetaMask.
                </p>

                <p><img className="my-20 width-full mx-auto" src="/HelpCenter/how_to_create_a_6uo_NFT_account/6uoGamePlace_wallet_provider.png"></img></p>
                
                <p>
                    &emsp; 3. Select your wallet address and click the "Connect button" 
                </p>
                
                <p><img className="width-full mx-auto" src="/HelpCenter/how_to_create_a_6uo_NFT_account/metamask_login.png"></img></p>

                <p><img className="width-full mx-auto" src="/HelpCenter/how_to_create_a_6uo_NFT_account/metamask_login2.png"></img></p>

                <p>
                    &emsp; 4. Once you have connected your wallet with 6uo NFT Gameplace, you will be asked to accept the terms of service before signing a login nonce to verify you are the wallet owner.
                </p>

                <p><img className="width-full mx-auto" src="/HelpCenter/how_to_create_a_6uo_NFT_account/6uoGamePlace_wallet_screenshot_2.png"></img></p>

                <p><img className="width-full mx-auto" src="/HelpCenter/how_to_create_a_6uo_NFT_account/6uoGamePlace_wallet_screenshot_3.png"></img></p>

                <p>
                    &emsp; 5. Congratulations! You have created a 6uo Gameplace account successfully. Let's enjoy buying, trading, creating, and selling NFTs using 6uo NFT Gameplace!
                </p>

            </div>
        
            </div>
        </div>
    )
}
