import React from "react"
import { useState } from "react"

export default function WrongChain( {switchNetwork} ) {
  const [show, setShow] = useState(true);
  return (
    <>
      {show && (
        <div className="bg-teal-600 sticky top-16 w-full z-20">
          <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
            <div className="flex flex-wrap items-center justify-between">
              <div className="flex w-0 flex-1 items-center">
                <span className="flex rounded-lg p-2">
                  <img src="/images/exclamation-mark.png" className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
                <p className="ml-3 truncate font-medium text-white">
                  <span className="md:hidden">Your wallet aren't connected to Sepolia.</span>
                  <span className="hidden md:inline">Your wallet aren't connected to Sepolia. Please switch to Sepolia to use our service.</span>
                </p>
              </div>
              <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
                <button
                  onClick={switchNetwork}
                  className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-teal-600 shadow-sm hover:bg-teal-700"
                >
                  Switch to Sepolia
                </button>
              </div>
              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                <button
                  type="button"
                  className="-mr-1 flex rounded-md hover:bg-teal-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                  onClick={() => setShow(false)}
                >
                  <span className="sr-only">Dismiss</span>
                  <img src="/images/cross-white2.png" className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
