import React, { useEffect } from 'react'
import Email from '../components/Home/Email'
import Intro from '../components/Home/Intro'
import Intro2 from '../components/Home/Intro2'
import NewestGames from '../components/Home/NewestGames'
import TopGames from '../components/Home/TopGames'
import Player from '../components/ProfileTabs/Player'

function Home( {auth} ) {
  useEffect(() => {
    document.title = "6uoGamePlace - Play and Trade Games"
  }, [])
  return (
    <div className='bg-white dark:bg-gray-800 min-w-[390px]'>
            {!auth ? (
              <>
                <Intro/>
                <Intro2/>
              </>
            )
            : (
              <>
                <Player/>
              </>
            )}
            <TopGames/>
            <NewestGames/>
            <Email/>
    </div>
  )
}

export default Home