import React from 'react'
import { useState, useEffect } from 'react'

function Email() {
  const [email, setEmail] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/news/subscribe?email=${email}&news_type=gamePlaceNews`);
    } catch (error) {
      console.log(error);
    }
    
  }

  return (
    <div className="bg-white dark:bg-gray-800 py-6 sm:py-8 lg:py-12">
        <div className="max-w-screen-2xl px-4 md:px-8 mx-auto">
            <div className="flex flex-col items-center bg-gray-100 dark:bg-gray-700 rounded-lg p-4 sm:p-8">
              <div className="mb-4 sm:mb-8">
                  <h2 className="text-teal-500 text-xl sm:text-2xl lg:text-3xl font-bold text-center">Get the latest updates</h2>
                  <p className="text-gray-500 text-center">Subscribe to our newsletter</p>
              </div>

              <form className="w-full max-w-md flex gap-2 mb-3 sm:mb-5">
                  <input value={email} onChange={(e)=> setEmail(e.target.value)} placeholder="Email" className="w-full flex-1 bg-gray-white text-gray-800 placeholder-gray-400 border border-gray-300 focus:ring ring-teal-300 rounded outline-none transition duration-100 px-3 py-2" />

                  <button type="submit" className="inline-block bg-teal-500 hover:bg-teal-600 active:bg-teal-700 focus-visible:ring ring-teal-300 text-white text-sm md:text-base font-semibold text-center rounded outline-none transition duration-100 px-8 py-2">Subscribe</button>
              </form>

              <p className="text-gray-400 text-xs text-center">By signing up to our newsletter you agree to our <a href="#" className="hover:text-teal-500 active:text-teal-600 underline transition duration-100">Term of Service</a> and <a href="#" className="hover:text-teal-500 active:text-teal-600 underline transition duration-100">Privacy Policy</a>.</p>
            </div>
        </div>
    </div>
  )
}

export default Email