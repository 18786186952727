import React , { useState , useEffect , useRef } from "react";
import { useLocation , useNavigate , useParams } from "react-router-dom";
import AccountTab from "../components/AccountTab";
import InviteStateModal from "../components/Popups/InviteStateModal";
import { truncateAddress } from "../connect/utils";

function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

export default function OtherAddress() {
    const { account } = useParams();
    const params = new URLSearchParams(window.location.pathname);

    const wrapperRef = useRef(null);

    const [name, setName] = useState();
    const [friend, setFriend] = useState();
    const [game, setGame] = useState();
    const [favorite, setFavorite] = useState();
    const [profilePic, setProfilePic] = useState();
    const [backgroundImage, setBackgroundImage] = useState();

    const [isNFT, setIsNFT] = useState();

    const [senderAccount, setSenderAccount] = useState();
    const [authToken, setAuthToken] = useState();

    const [friendAddresses, setFriendAddresses] = useState();

    const [isInvite, setIsInvite] = useState();

    const [state, setState] = useState();

    const is0Ac = useRef(true);

    const navigate = useNavigate();

    const [copied, setCopied] = useState();
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const tab = query.get("tab");

    // if (!auth) {
    //     navigate("/");
    // }
    
    const copyToClipboard = () => {
        setCopied(true);
        navigator.clipboard.writeText(account);

        console.log("copied", copied);

        setTimeout(() => {
            setCopied(false);
        } , 1500);
    };

    const getProfile = async () => {
        try{
            fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/profile/${account}/info`)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.message != 'Success') {
                        navigate("/something_went_wrong");
                    }
                    if (data.profile.name == null) {
                        setName('User');
                    } else {
                        setName(data.profile.name);
                    }
                    setFriend(data.profile.friend);
                    setGame(data.profile.game);
                    setFavorite(data.profile.favorite);
                });
        } catch (error) {
            console.log(error);
        }
    }

    const getProfileImages = () => {
        try{
            fetch (`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/profile/${account}/images`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
              })
              .then(res => res.json())
              .then(data => {
                console.log(data);
                let imgList = ['png', 'jpg', 'jpeg', 'gif'];
                // console.log('icon_path: ', data.profile.icon_path.split('.')[1]);
                if (data.profile.icon_path !== null) {
                    if (imgList.includes(data.profile.icon_path.split('.')[1])) {
                        console.log('icon is image');
                        var img = `${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/icon/${data.profile.icon_path}`;
                        setProfilePic(img);
                    } else {
                        setIsNFT(true);
                        setProfilePic(data.profile.icon_path);
                    }
                }
                if (data.profile.icon_path !== null) {
                    var banner = `${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/banner/${data.profile.background_path}`;
                    setBackgroundImage(banner);
                }

                console.log("backgroundImage: ", backgroundImage);
              })
        } catch (error) {
          console.log(error);
        }
    }

    const getFriends = async () => {
        if (senderAccount == account) {
            return ;
        }
        try{
            fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/friends/getFriendList`, {
                body: JSON.stringify({
                    owner_address: `${senderAccount}`,
                    session_token: `${authToken}`,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
                })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setFriendAddresses(data.friend_list);
                });
        } catch (error) {
            console.log(error);
        }
    }

    const inviteHandler = async () => {
        try{
            console.log('inviting someone...');
            console.log('senderAddress', senderAccount);
            console.log('session_token', authToken);
            // setState('inviting someone...');
            fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/friends/invite/${account}`, {
                body: JSON.stringify({
                    owner_address: `${senderAccount}`,
                    session_token: `${authToken}`,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
                })
                .then((response) => {
                    console.log(response);
                    // response.statusText == 'Bad Request' ? setState('failed') : setState(response.statusText);
                    return response.json();
                })
                .then((data) => {
                    console.log(data)
                    console.log("invitation's data", data.message);
                    var message = data.message;
                    data.message == 'Bad Request' ? setState('Failed') : setState(message);
                });
        } catch (error) {
            console.log(error);
        }
    }

    const cancelHandler = async () => {
        try{
            console.log('canceling...')
            fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/friends/cancel/${account}`, {
                body: JSON.stringify({
                    owner_address: `${senderAccount}`,
                    session_token: `${authToken}`,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
                })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log("cancel's data", data);
                    var message = data.message;
                    setState(message);
                });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        function handleClickOutside(event) {
          if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setState(null);
          }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [wrapperRef]);

    useEffect(() => {
        getProfile();
        getProfileImages();
    },[])

    useEffect(() => {
        if (is0Ac.current) {
            is0Ac.current = false;
            return;
        }
        getFriends();
    },[senderAccount])

    useEffect(() => {
      function handleWindowResize() {
        setWindowSize(getWindowSize());
      }
  
      window.addEventListener('resize', handleWindowResize);
  
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);

    useEffect(() => {
        var senderAccount;
        var authToken;
        try {
            senderAccount = JSON.parse(localStorage.getItem("account"));
        } catch (e) {
            senderAccount = localStorage.getItem("account");
        }
        try {
            authToken = JSON.parse(localStorage.getItem("authToken"));
        } catch (e) {
            authToken = localStorage.getItem("authToken");
        }
        setSenderAccount(senderAccount);
        setAuthToken(authToken);
    },[])

    // useEffect(() => {
    //     if (windowSize) {
    //         console.log("windowSize", windowSize);
    //     }
    // }, [windowSize]);

    return (
        <main className="profile-page">
            <section className="relative block" style={{ height: "500px" }}>
            <div
                className="absolute top-0 w-full h-full bg-center bg-cover"
                style={{
                backgroundImage:
                    `url(${backgroundImage})`
                }}
            >
                <span
                id="blackOverlay"
                className="w-full h-full absolute opacity-50 bg-black dark:bg-gray-800"
                ></span>
            </div>
            <div
                className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
                style={{ height: "70px" }}
            >
                <svg
                className="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
                >
                <polygon
                    className="text-gray-300 dark:text-gray-700 fill-current"
                    points="2560 0 2560 100 0 100"
                ></polygon>
                </svg>
            </div>
            </section>
            <section className="relative py-8 sm:py-16 bg-gray-300 dark:bg-gray-700">
            <div className="container mx-auto px-4">
                <div className="relative flex flex-col min-w-0 break-words bg-white dark:bg-gray-800 w-full mb-6 shadow-xl rounded-lg -mt-64">
                    <div className="px-6">
                        <div className="flex flex-wrap justify-center">
                            <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                                <div className="relative flex justify-center">
                                    {!isNFT ? (
                                        <div 
                                            className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 overflow-hidden"
                                            style={{ width: "150px" , height: "150px"}}
                                        >
                                            {( profilePic == null ) ? (
                                                    <div 
                                                        style={{background: `#${account.toString().slice(2, 8).toString()}`}}
                                                        className="w-full h-full object-cover"
                                                    >
                                                        <img
                                                            className="h-full w-full rounded-full object-cover object-center"
                                                            src={`/images/chess_icons/${account.toString().match(/\d+$/)%5}.svg`}
                                                            alt=""
                                                        />
                                                    </div>
                                            ) : (
                                                    <img
                                                        alt="..."
                                                        src={profilePic}
                                                        className="w-full h-full object-cover"
                                                    />
                                            )}
                                        </div>
                                    ) : (
                                        <div
                                            className="shadow-xl mask mask-hexagon-2 h-auto align-middle border-none absolute -m-16 overflow-hidden"
                                            style={{ width: "150px" , height: "150px"}}
                                        >
                                            
                                            <img
                                                alt="..."
                                                src={profilePic}
                                                className="w-full h-full object-cover"
                                            />
                                        </div>
                                    )}

                                </div>
                            </div>
                            <div className="w-full lg:w-4/12 px-4 text-center lg:order-3 lg:text-right lg:self-center">
                                <div className="py-6 px-3 mt-32 lg:mt-0">
                                    <button
                                        className="bg-teal-500 active:bg-teal-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1"
                                        type="button"
                                        style={{ transition: "all .15s ease" }}
                                        onClick={() => inviteHandler()}
                                    >
                                        invite
                                    </button>
                                    <button
                                        className="bg-teal-500 active:bg-teal-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1"
                                        type="button"
                                        style={{ transition: "all .15s ease" }}
                                        onClick={() => cancelHandler()}
                                    >
                                        cancel
                                    </button>
                                </div>
                            </div>
                            <div className="w-full lg:w-4/12 px-4 lg:order-1">
                                <div className="flex justify-center py-4 lg:pt-4 pt-8">
                                <div className="mr-4 p-3 text-center">
                                    {friend != null ? (<span className="text-xl font-bold block uppercase tracking-wide text-gray-700 dark:text-gray-200">
                                        {friend}
                                    </span>) : (<div className="bg-gray-300 dark:bg-gray-700 rounded-lg animate-pulse h-[28px] w-5 mx-[14.075px]"></div>)}
                                    <span className="text-sm text-gray-500 dark:text-gray-400">Friends</span>
                                </div>
                                <div className="mr-4 p-3 text-center">
                                    {game != null ? (<span className="text-xl font-bold block uppercase tracking-wide text-gray-700 dark:text-gray-200">
                                        {game}
                                    </span>) : (<div className="bg-gray-300 dark:bg-gray-700 rounded-lg animate-pulse h-[28px] w-5 mx-[12.47px]"></div>)}
                                    <span className="text-sm text-gray-500 dark:text-gray-400">Games</span>
                                </div>
                                <div className="lg:mr-4 p-3 text-center">
                                    {favorite != null ? (<span className="text-xl font-bold block uppercase tracking-wide text-gray-700 dark:text-gray-200">
                                        {favorite}
                                    </span>) : (<div className="bg-gray-300 dark:bg-gray-700 rounded-lg animate-pulse h-[28px] w-5 mx-[19.16px]"></div>)}
                                    <span className="text-sm text-gray-500 dark:text-gray-400">Favorites</span>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-12">
                            {name != null ? (<h3 className="text-4xl font-semibold leading-normal text-gray-800 dark:text-gray-200 mb-2">
                                {name}
                            </h3>) : (<div className="bg-gray-300 dark:bg-gray-700 rounded-lg animate-pulse h-[54px] w-48 mx-auto mb-2"></div>)}
                            <div className="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase">
                                {/* <i className="fas fa-map-marker-alt mr-2 text-lg text-gray-500"></i>{" "} */}
                                {copied && (
                                    <div className="inline-block absolute left-1/2 transform -translate-x-1/2 z-50 py-2 px-3 mt-[-36px] text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm transition-opacity duration-300 dark:bg-gray-700">
                                        Copied!
                                    </div>
                                )}
                                <div className="flex flex-row justify-center">
                                    { windowSize.innerWidth < 640 ? (
                                        <button onClick={copyToClipboard} className='flex justify-between'>
                                            {truncateAddress(account)}
                                            <div className="pl-2 pt-0.5">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" className="fill-gray-500 w-3.5 h-3.5">
                                                    <path fillRule="evenodd" d="M0 6.75C0 5.784.784 5 1.75 5h1.5a.75.75 0 010 1.5h-1.5a.25.25 0 00-.25.25v7.5c0 .138.112.25.25.25h7.5a.25.25 0 00.25-.25v-1.5a.75.75 0 011.5 0v1.5A1.75 1.75 0 019.25 16h-7.5A1.75 1.75 0 010 14.25v-7.5z"></path>
                                                    <path fillRule="evenodd" d="M5 1.75C5 .784 5.784 0 6.75 0h7.5C15.216 0 16 .784 16 1.75v7.5A1.75 1.75 0 0114.25 11h-7.5A1.75 1.75 0 015 9.25v-7.5zm1.75-.25a.25.25 0 00-.25.25v7.5c0 .138.112.25.25.25h7.5a.25.25 0 00.25-.25v-7.5a.25.25 0 00-.25-.25h-7.5z"></path>
                                                </svg>
                                            </div>
                                        </button>
                                    ) : (
                                        <button onClick={copyToClipboard} className='flex justify-between'>
                                            {account}
                                            <div className="pl-2 pt-0.5">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" className="fill-gray-500 w-3.5 h-3.5">
                                                    <path fillRule="evenodd" d="M0 6.75C0 5.784.784 5 1.75 5h1.5a.75.75 0 010 1.5h-1.5a.25.25 0 00-.25.25v7.5c0 .138.112.25.25.25h7.5a.25.25 0 00.25-.25v-1.5a.75.75 0 011.5 0v1.5A1.75 1.75 0 019.25 16h-7.5A1.75 1.75 0 010 14.25v-7.5z"></path>
                                                    <path fillRule="evenodd" d="M5 1.75C5 .784 5.784 0 6.75 0h7.5C15.216 0 16 .784 16 1.75v7.5A1.75 1.75 0 0114.25 11h-7.5A1.75 1.75 0 015 9.25v-7.5zm1.75-.25a.25.25 0 00-.25.25v7.5c0 .138.112.25.25.25h7.5a.25.25 0 00.25-.25v-7.5a.25.25 0 00-.25-.25h-7.5z"></path>
                                                </svg>
                                            </div>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <AccountTab />
                    </div>
                </div>
            </div>
            </section>
            {
                state != null && (
                    <InviteStateModal state={state} wrapperRef={wrapperRef} />
                )
            }
        </main>
    );
}
