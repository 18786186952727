import React from 'react';
import { useLocation , useSearchParams } from 'react-router-dom';
import Seller from './ProfileTabs/Seller';
import Player from './ProfileTabs/Player';
import FriendList from './ProfileTabs/FriendList';
import FavoriteList from './ProfileTabs/FavoriteList';
import YourNFTs from './ProfileTabs/YourNFTs';

function ProfileTab( { onChanged } ) {
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const currentTab = query.get("tab");

    const [tabState, setTabState] = useSearchParams();

    const handleTabChange = (tabOnClick) => {
        const tab = tabOnClick;

        if (tab) {
            setTabState({ tab });
        } else {
            tabState({});
        }
    };

    const onChangedIcon = () => {
        console.log("onChangedIcon");
        onChanged();
    }

    const renderTab = () => {
        switch (currentTab) {
            case "ActiveListings":
                return <Seller />;
            case "OwnedItems":
                return <Player />;
            case "Friends":
                return <FriendList />;
            case "Favorite":
                return <FavoriteList />;
            case "YourNFTs":
                return <YourNFTs onChangedIcon={ onChangedIcon } />;
            default:
                return <Player />;
        }
    }

    return (
        <div>
            <button onClick={() => handleTabChange("OwnedItems")} className={(currentTab !== "ActiveListings" && currentTab !== "Friends" && currentTab !== "Favorite" && currentTab !== "YourNFTs" ) ? "m-3 text-teal-500 text-lg" : "m-3 dark:text-gray-200 text-lg hover:scale-105"}>Owned Items</button>
            <button onClick={() => handleTabChange("ActiveListings")} className={(currentTab == "ActiveListings") ? "m-3 text-teal-500 text-lg" : "m-3 dark:text-gray-200 text-lg hover:scale-105"}>Active Listings</button>
            <button onClick={() => handleTabChange("Friends")} className={(currentTab == "Friends") ? "m-3 text-teal-500 text-lg" : "m-3 dark:text-gray-200 text-lg hover:scale-105"}>Friends</button>
            <button onClick={() => handleTabChange("Favorite")} className={(currentTab == "Favorite") ? "m-3 text-teal-500 text-lg" : "m-3 dark:text-gray-200 text-lg hover:scale-105"}>Favorite</button>
            <button onClick={() => handleTabChange("YourNFTs")} className={(currentTab == "YourNFTs") ? "m-3 text-teal-500 text-lg" : "m-3 dark:text-gray-200 text-lg hover:scale-105"}>Your NFTs</button>
            {/* <a href="/test?tab=Favorited" className="mr-5 dark:text-gray-200">Favorited</a> */}
            <div className="py-10 border-t border-gray-300 text-center">
                <div className="flex flex-wrap justify-center">
                    <div className="w-full px-4">
                        {renderTab()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileTab