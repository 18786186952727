
import React from "react";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import SkeletonPlayer from "../SkeletonLoading/SkeletonPlayer";

import LibraryContext from "../../LibraryContext";
// import Icon from "../Icon.js";

export default function FavoriteList() {
	const [sessionToken, setSessionToken] = useState();
	const [ownerAccount, setOwnerAccount] = useState();
	const [loadingState, setLoadingState] = useState("not-loaded");
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	var currentUrl = window.location.pathname;

    const [favoriteList, setFavoriteList] = useState([]);

	const is0Ac = useRef(true);

    const getFavorite = () => {
        try {
            fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/getFavorite`, {
                body: JSON.stringify({
                  token: `${sessionToken}`,
                  public_address: `${ownerAccount}`,
                }),
                headers: {
                  'Content-Type': 'application/json',
                },
                method: 'POST',
            }).then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            }).then((data) => {
                console.log(data);
                setFavoriteList(data.game_list);
            });
        } catch (e) {
            console.log(e);
        }
    }
	

    

	useEffect(() => {
        var account;
        var authToken;
        try {
            account = JSON.parse(localStorage.getItem("account"));
        } catch (e) {
            account = localStorage.getItem("account");
        }
        try {
            authToken = JSON.parse(localStorage.getItem("authToken"));
        } catch (e) {
            authToken = localStorage.getItem("authToken");
        }
        setOwnerAccount(account);
        setSessionToken(authToken);
    },[])

    useEffect(() => {
        if (is0Ac.current) {
            is0Ac.current = false;
            return;
        }
        getFavorite();
    },[ownerAccount])


	return (
		<div className="flex justify-center pb-4 bg-white dark:bg-gray-800">
			<div className="">
				{isLoading ? (
					<SkeletonPlayer />
				) : (
					<div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 pt-4">
						{
							favoriteList.map((list, i) => (
								<div key={i} className="group border dark:bg-gray-700 dark:border-gray-600 dark:hover:bg-gray-600 shadow rounded-xl overflow-hidden h-128 w-64">
									<Link to={`/game/${list.tokenId}`}>
										<div className='w-64 h-64 flex justify-end items-end bg-gray-100 overflow-hidden shadow-lg relative'>
											<img src={list.image} className='w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200' alt="nft" />
										</div>
									</Link>
									<div className="p-4">
										<p className="test-2xl font-semibold dark:text-gray-200">{list.name}</p>
										{/* <p className="text-1xl font-bold dark:text-gray-400">{list.amount} owned</p> */}
										{/* <button className="mt-4 w-full bg-teal-500 text-white font-bold py-2 px-12 rounded hover:bg-teal-400" onClick={function(){playGameHandler(parseInt(nft.tokenId.hex, 16))}}>Play!</button> */}
									</div>
								</div>
							))
						}
					</div>
				)}
			</div>
		</div>
	)
}