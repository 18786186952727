import React, { useContext } from 'react'
import { useEffect , useState , useRef } from "react"
import Web3Modal, { connectors } from "web3modal"
import { truncateAddress } from "./utils"
import { providerOptions } from './providerOptions'
import { Steps } from 'rsuite';
import Email from '../components/Home/Email'
import Player from '../components/ProfileTabs/Player'
import { useNavigate } from 'react-router-dom'

const web3Modal = new Web3Modal({
    cacheProvider: true, // optional
    providerOptions // required
})

function Wallet( {auth, library, agreement, onSignInTo6uo, onLoggedInToMetaMask, onLoggendInToCoinbase, onLoggedInToWalletConnect, onLoggedOut} ) {
    const [account, setAccount] = useState()
    const [chainId, setChainId] = useState()
    const [showReminder, setShowReminder] = useState(false)
    const [connectStatus, setConnectStatus] = useState(0)
    const wrapperRef = useRef(null);
    const [safari, setSafari] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                console.log("You clicked outside of me!");
                setShowReminder(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    useEffect(() => {
        if (navigator.userAgent.indexOf('Safari') == 102) {
            console.log("Safari detected")
            console.log("navigator.userAgent.indexOf('Safari')", navigator.userAgent.indexOf("Safari"))
            setSafari(true)
        }
    }, [])

    const connectWallet = async () => {
        try {
            console.log("Connecting wallet...")
            console.log("library: ", library)
            const accounts = await library.listAccounts();
            const network = await library.getNetwork();
            if (accounts) setAccount(accounts[0]);
            setChainId(network.chainId);
        } catch (error) {
            console.log(error);  
        }
    };

    const metamaskHandler = async () => {
        try {
            onLoggedInToMetaMask();
            setShowReminder(true);
        } catch (error) {
            console.log(error);
        }
    }

    const coinbaseHandler = async () => {
        try {
            onLoggendInToCoinbase();
            setShowReminder(true);
        } catch (error) {
            console.log(error);
        }
    }

    const walletConnectHandler = async () => {
        try {
            onLoggedInToWalletConnect();
            setShowReminder(true);
        } catch (error) {
            console.log(error);
        }
    }

    const agreeHandler = async () => {
        try {
            onSignInTo6uo();
            setShowReminder(false);
        } catch (error) {
            console.log(error);
        }
    }

    const disconnectWallet = async () => {
        try {
            onLoggedOut();
            setConnectStatus(0);
            setShowReminder(false);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        setAccount(localStorage.getItem('account'))
        setChainId(localStorage.getItem('chainId'))
    }, [])

    useEffect(() => {
        window.localStorage.setItem('account', account)
        window.localStorage.setItem('chainId', chainId)
    }, [account, chainId])

    useEffect( () => {
        if ( library ){
            connectWallet();
            navigate('/')
        }
    }, [ auth ])

    useEffect( () => {
        if ( library ){
            setConnectStatus(1);
        }
    }, [ library ])

    useEffect( () => {
        console.log("showReminder: ", showReminder);
    }, [ showReminder ])
    
    return (
        <div>
            <div className='flex flex-col justify-center items-center h-[calc(100vh-74px-325px)] dark:bg-gray-800 dark:text-white'>
                {
                    !auth ? (
                        <div className='mb-10' >
                            <p className='m-0 leading-tight text-2xl font-semibold dark'>
                                Let's connect with your crypto wallet
                            </p>
                        </div>
                    ) : (
                        <div className='mb-10' >
                            <p className='m-0 leading-tight text-2xl font-semibold dark'>
                                Your account: {account ? truncateAddress(account) : "loading..."}
                            </p>
                        </div>
                    )
                }
                <div className='isolation-auto w-1/4'>
                    {
                        !auth ? (
                            <div className='flex flex-col gap-5'>
                                {!safari && (
                                    <button onClick={metamaskHandler} className='border border-gray-400 hover:border-teal-700 font-bold py-2 px-4 rounded-xl'>
                                        <div className='flex flex-row gap-2'>
                                            <img src='images/metamask.png' className='w-8 h-8 inline-block mr-2' />
                                            <p className='flex flex-col justify-center items-center'>MetaMask</p>
                                        </div>
                                    </button>
                                )}
                                <button onClick={coinbaseHandler} className='border border-gray-400 hover:border-teal-700 font-bold py-2 px-4 rounded-xl'>
                                    <div className='flex flex-row gap-2'>
                                        <img src='images/coinbasewallet.png' className='w-8 h-8 inline-block mr-2' />
                                        <p className='flex flex-col justify-center items-center'>Coinbase Wallet</p>
                                    </div>
                                </button>
                                <button onClick={walletConnectHandler} className='border border-gray-400 hover:border-teal-700 font-bold py-2 px-4 rounded-xl'>
                                    <div className='flex flex-row gap-2'>
                                        <img src='images/walletconnect.png' className='w-8 h-8 inline-block mr-2' />
                                        <p className='flex flex-col justify-center items-center'>WalletConnect</p>
                                    </div>
                                </button>
                                { showReminder && (
                                    <>
                                        <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
                                            <div className='relative w-auto my-6 mx-auto max-w-6xl'>
                                                
                                                <div ref={wrapperRef} className='border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-10'>
                                                    
                                                    <div className='flex flex-col items-center justify-center p-5 border-solid border-gray-300 rounded-t'>
                                                        <p className='text-2xl font-semibold'>Connect with your Crypto Wallet</p>
                                                    </div>
                                                   
                                                    <div className='relative p-5 flex justify-center'>
                                                        <Steps current={connectStatus} vertical>
                                                            <Steps.Item title="Connect Wallet" />
                                                            <Steps.Item title="Signature Request" />
                                                            <Steps.Item title="Done" />
                                                        </Steps>
                                                    </div>

                                                    { agreement == 0 && (
                                                        <div className="flex flex-col items-center justify-center p-3 border-t border-solid border-gray-300 rounded-b gap-2.5">
                                                            <div className='flex flex-row py-2 items-center'>
                                                                <p className='pl-2 pr-1'>Before sign in with your wallet and using 6uo Game Place, your agree to our</p>
                                                                <a href='#' className='text-teal-500 hover:text-teal-700'>terms of service</a>
                                                            </div>
                                                            <div className='flex flex-row pt-2 items-center gap-5'>
                                                                <button
                                                                    className="w-36 bg-teal-500 text-white active:bg-teal-700 font-bold uppercase py-3 text-sm rounded-xl shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                                                                    type="button"
                                                                    onClick={agreeHandler}
                                                                >
                                                                    Accept
                                                                </button>
                                                                <button
                                                                    className="w-36 bg-gray-500 text-white active:bg-gray-600 font-bold uppercase text-sm py-3 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                                                                    type="button"
                                                                    onClick={() => setShowReminder(false)}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                    
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                    </>
                                )}
                            </div>
                        ) : (
                            <div className='flex flex-col justify-center items-center'>
                                <button onClick={disconnectWallet} className='bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded-full'>Disconnect</button>
                            </div>
                        )
                    }
                </div>
            </div>
            {
                !auth && (
                    < Email />
                )
            }
        </div>
    )
}

export { Wallet, web3Modal };