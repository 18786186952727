import React from 'react';
import BuyingSideMenu from '../components/BuyingSideMenu';
import BuyingPath from '../components/BuyingPath';

export default function How_do_I_buy_games() {

    return (
        <div className='flex dark:bg-gray-800 dark:text-gray-200'>
            
            <BuyingSideMenu/>

            <div className='flex flex-col'>

            <BuyingPath/>

            <div className="m-20 mt-0 inline-block text-lg">

                <h1 className="text-4xl mt-10 mb-10">
                    <p>How do I buy games?</p>
                </h1>

                <p>
                    Click on the game you want to buy to browse the game detail page.
                </p>

                <p>
                    &emsp; 1. Buy a game access token from the game developer.
                </p>

                <p>
                    Some games might have second way to purchase:
                </p>
                
                <p>
                    &emsp; 2. Buy game from other player.
                </p>
                
            </div>
        
            </div>
        </div>
    )
}
