import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function HelpNav() {

    const navigate = useNavigate();

    const handleTabChange = (question) => {
        navigate(question);
    }

  return (

    <div className='dark:bg-gray-800 dark:text-gray-200 min-h-screen flex flex-col justify-center'>
        <h3 className='text-center text-5xl m-6 2xl:mt-0 font-bold'>Help Center</h3>
        <div className="w-3/4 mx-auto my-10 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-8 content-center">
                
            <button onClick={() => handleTabChange("Getting_Started")}>
                <div className="border dark:border-gray-600 shadow rounded-xl overflow-hidden h-56 w-full text-center m-3">
                    
                    <div className='flex justify-center items-center h-[185px]'>
                        <img className="h-4/5 object-contain" src="/HelpCenter/nav/gettingStarted.svg"></img>
                    </div>
                    
                    
                    <div className="">
                        Getting Started
                    </div>

                </div>
            </button>

            <button onClick={() => handleTabChange("Buying")}>
                <div className="border dark:border-gray-600 shadow rounded-xl overflow-hidden h-56 w-full text-center m-3">
                    
                    <div className='flex justify-center items-center h-[185px]'>
                        <img className="h-4/5 object-contain" src="/HelpCenter/nav/buying.svg">
                        
                        </img>
                    </div>
                    
                    <div className="">
                        Buying
                    </div>

                </div>
            </button>

            <button onClick={() => handleTabChange("Selling")}>
                <div className="border dark:border-gray-600 shadow rounded-xl overflow-hidden h-56 w-full text-center m-3">
                    
                    <div className='flex justify-center items-center h-[185px]'>
                        <img className="h-4/5 object-contain" src="/HelpCenter/nav/selling.svg">

                        </img>
                    </div>
                    
                    <div className="">
                        Selling
                    </div>

                </div>
            </button>

            <button onClick={() => handleTabChange("Creating")}>
                <div className="border dark:border-gray-600 shadow rounded-xl overflow-hidden h-56 w-full text-center m-3">
                    
                    <div className='flex justify-center items-center h-[185px]'>
                        <img className="h-4/5 object-contain" src="/HelpCenter/nav/creating.svg">
                        
                        </img>
                    </div>
                    
                    <div className="">
                        Creating
                    </div>

                </div>
            </button>

            <button onClick={() => handleTabChange("Policies")}>
                <div className="border dark:border-gray-600 shadow rounded-xl overflow-hidden h-56 w-full text-center m-3">
                    
                    <div className='flex justify-center items-center h-[185px]'>
                        <img className="h-4/5 object-contain" src="/HelpCenter/nav/policies.svg">
                        
                        </img>
                    </div>
                    
                    <div className="">
                        Policies
                    </div>

                </div>
            </button>

            <button onClick={() => handleTabChange("FAQ")}>
                <div className="border dark:border-gray-600 shadow rounded-xl overflow-hidden h-56 w-full text-center m-3">
                    
                    <div className='flex justify-center items-center h-[185px]'>
                        <img className="h-4/5 object-contain" src="/HelpCenter/nav/faq.svg">
                        
                        </img>
                    </div>
                    
                    <div className="">
                        FAQ
                    </div>

                </div>
            </button>

        </div>
    </div>
  )
}
