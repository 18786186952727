import React, { useState , useEffect , useRef } from 'react';
import { AiOutlineUser, AiOutlineMail, AiOutlineMessage } from 'react-icons/ai';
import { BiSave } from 'react-icons/bi';
import { FileUploader } from "react-drag-drop-files";
import axios from 'axios';

function Settings() {
  const [ senderAccount , setSenderAccount ] = useState();
  const [ authToken , setAuthToken ] = useState();

  const [ ok , setOK ] = useState(false);
  const is0Ac = useRef(true);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [bio, setBio] = useState('');
  const [image, setImage] = useState([]);
  const [banner, setBanner] = useState([]);

  const [profilePic, setProfilePic] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('');
  const [isNFT, setIsNFT] = useState(false);

  const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];

  const getProfileImages = () => {
    try{
        fetch (`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/profile/${senderAccount}/images`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
          })
          .then(res => res.json())
          .then(data => {
            console.log(data);
            if (data.profile.icon_path !== null) {
              if (fileTypes.includes(data.profile.icon_path.split('.')[1])) {
                  console.log('icon is image');
                  var img = `${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/icon/${data.profile.icon_path}`;
                  setProfilePic(img);
              } else {
                  setIsNFT(true);
                  setProfilePic(data.profile.icon_path);
              }
            }
            if (data.profile.icon_path !== null) {
              var banner = `${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/banner/${data.profile.background_path}`;
              setBackgroundImage(banner);
            }
          })
    } catch (error) {
      console.log(error);
    }
  }

  const getProfileDetails = () => {
    try{
        fetch (`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/profile/${senderAccount}/details`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
          })
          .then(res => res.json())
          .then(data => {
            console.log(data);
            setName(data.profile.name);
            setEmail(data.profile.email);
            setBio(data.profile.bio);
          })
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitting form...', name, email, bio, profilePic, backgroundImage);
    // Here you can make an API call to update the user's profile settings
    if ( image ) {
      try {
        let formData = new FormData();
        formData.append("imgfile",image);
        formData.append("senderAccount",senderAccount);
        formData.append("authToken",authToken);
        let res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/profile/iconupload`,formData);
        console.log('image: ', res.data);
      } catch (error) {
        console.log(error);
      }
    }

    if ( banner ) {
      try {
        let formData = new FormData();
        formData.append("imgfile",banner);
        formData.append("senderAccount",senderAccount);
        formData.append("authToken",authToken);
        let res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/profile/bannerupload`,formData);
      } catch (error) {
        console.log('banner: ', error);
        console.log('banner msg: ', error.response.data);
      }
    }

    if ( name || email || bio ) {
      try {
        let formData = new FormData();
        formData.append("name",name);
        formData.append("email",email);
        formData.append("bio",bio);
        formData.append("senderAccount",senderAccount);
        formData.append("authToken",authToken);
        let res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/profile/update`,formData);
        console.log('update: ', res.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleProfilePicInputChange = (file) => {
    if ( file ) {
      setImage(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        setProfilePic(e.target.result);
      };
    }
  };

  const handleBackgroundImageInputChange = (file) => {
    if ( file ) {
      setBanner(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        setBackgroundImage(e.target.result);
      };
    }
  };

  useEffect(() => {
    if (is0Ac.current) {
      is0Ac.current = false;
      return;
    }
    getProfileImages();
    getProfileDetails();
    setOK(true);
  },[senderAccount])

  useEffect(() => {
    var senderAccount;
    var authToken;
    try {
        senderAccount = JSON.parse(localStorage.getItem("account"));
    } catch (e) {
        senderAccount = localStorage.getItem("account");
    }
    try {
        authToken = JSON.parse(localStorage.getItem("authToken"));
    } catch (e) {
        authToken = localStorage.getItem("authToken");
    }
    setSenderAccount(senderAccount);
    setAuthToken(authToken);
  },[])

  return (
    <div className="max-w-3xl mx-auto mt-8 dark:text-gray-200">
      <h1 className="text-2xl font-bold mb-4">Profile Settings</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4 w-full flex items-center bg-gray-100 dark:bg-gray-700 border border-gray-200 dark:border-gray-900 rounded-lg p-5">
          {/* <AiOutlineUserAdd className="w-6 h-6 text-gray-600 dark:text-gray-400 mr-2" /> */}
          {/* <label htmlFor="profilePic" className="block text-gray-700 dark:text-gray-300 font-medium mb-2">Profile Picture:</label> */}
          <FileUploader handleChange={handleBackgroundImageInputChange} id="backgroundImage" name="file" types={fileTypes} className="flex items-center justify-center">
              <label htmlFor="backgroundImage" className="flex flex-col items-center justify-center min-w-[726px] h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                  {!backgroundImage ? (
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <p className="mb-5 text-2xl text-gray-600 dark:text-gray-300 font-semibold">Your Profile Background</p>
                        <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG or JPG </p>
                    </div>
                  ) : (
                    <img src={backgroundImage} alt="Profile" className="rounded-lg h-full w-full object-cover" />
                  )}
                  {/* <input id="backgroundImage" type="file" onChange={handleBackgroundImageInputChange} className="hidden" /> */}
              </label>
          </FileUploader>
        </div>
        <div className='flex justify-between gap-3'>
          <div className="flex items-center bg-gray-100 dark:bg-gray-700 border border-gray-200 dark:border-gray-900 rounded-lg p-4 h-72 w-72">
            {/* <AiOutlineUserAdd className="w-6 h-6 text-gray-600 dark:text-gray-400 mr-2" /> */}
            {/* <label htmlFor="profilePic" className="block text-gray-700 dark:text-gray-300 font-medium mb-2">Profile Picture:</label> */}
            <FileUploader handleChange={handleProfilePicInputChange} id="profilePic" name="file" types={fileTypes} className="flex items-center justify-center">
                <label htmlFor="profilePic" className="flex flex-col items-center justify-center w-[256px] h-[256px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                    {!profilePic ? (
                      <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <p className="mb-5 text-2xl text-gray-600 dark:text-gray-300 font-semibold">Your Icon</p>
                          <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                          <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF </p>
                      </div>
                    ) : (
                      <>
                        <img src={profilePic} alt="Profile" className="rounded-lg h-full w-full object-cover" />
                        {isNFT && (<span className="absolute right-0 bottom-0 h-6 w-6 rounded-full bg-teal-400 ring ring-offset-black text-teal-900 flex justify-center items-center font-black">N</span>)}
                      </>
                    )}
                    {/* <input id="profilePic" type="file" onChange={handleProfilePicInputChange} className="hidden" /> */}
                    {/* <input ref={inputRef} type="file" id="profilePic" multiple={true} onChange={handleProfilePicInputChange} className="hidden"/> */}
                </label>
                {/* { dragActive && <div id="profilePic" onDragEnter={handleDragProfilePic} onDragLeave={handleDragProfilePic} onDragOver={handleDragProfilePic} onDrop={handleDropProfilePic}></div> } */}
            </FileUploader>
          </div>
          <div className="bg-gray-100 dark:bg-gray-700 border border-gray-200 dark:border-gray-900 rounded-lg p-5 flex flex-col justify-between flex-auto h-auto">
            <h5 className='text-2xl font-bold'>Personal Information</h5>
            <div className="mb-2 flex items-center ">
              <AiOutlineUser className="w-6 h-6 text-gray-600 dark:text-gray-400 mr-2" />
              {/* <label htmlFor="name" className="block text-gray-700 dark:text-gray-300 font-medium mb-2">Name:</label> */}
              <input
                // type="text"
                id="name"
                placeholder={name ? `${name}` : 'Name'}
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-400 dark:bg-gray-600 dark:border-gray-700"
              />
            </div>
            <div className="mb-2 flex items-center">
              <AiOutlineMail className="w-6 h-6 text-gray-600 dark:text-gray-400 mr-2" />
              {/* <label htmlFor="email" className="block text-gray-700 dark:text-gray-300 font-medium mb-2">Email:</label> */}
              <input
                // type="email"
                id="email"
                placeholder={email ? `${email}` : 'Email'}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-400 dark:bg-gray-600 dark:border-gray-700"
              />
            </div>
            <div className="mb-2 flex items-center">
              <AiOutlineMessage className="w-6 h-6 text-gray-600 dark:text-gray-400 mr-2" />
              {/* <label htmlFor="password" className="block text-gray-700 dark:text-gray-300 font-medium mb-2">Password:</label> */}
              <textarea
                // type="bio"
                id="bio"
                placeholder={bio ? `${bio}` : 'Bio'}
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-400 dark:bg-gray-600 dark:border-gray-700"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end p-5">
          {
            ok && (
              <button
                type="submit"
                className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                <BiSave className="inline-block w-4 h-4 mr-2" />
                Save
              </button>
            )
          }
        </div>
      </form>
    </div>
  );
}

export default Settings;