import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function SellingSideMenu() {
  
  function getCurrentURL () {
    return window.location.pathname
  }
  
  const url = getCurrentURL()

  const navigate = useNavigate();

  const handleTabChange = (question) => {
      navigate(question);
  }

  return (
    <div className="w-60 shadow-md bg-white dark:bg-gray-800 px-1 pl-10 py-20 Absolute hidden md:block">
        <ul className="relative">
            <li className="">
              <p className="flex items-center text-xl py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 whitespace-nowrap">Articles in this section</p>
            </li>
            
            <li className="relative cursor-pointer my-2">
              <p
                className={
                  (url == `/Help-center/Selling/How_do_I_publish_games_on_6uo_Gameplace`)
                  ?
                  "flex items-center text-sm py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 text-ellipsis rounded transition duration-300 ease-in-out border border-teal-500"
                  :
                  "flex items-center text-sm py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 text-ellipsis rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out"
                }
                onClick={
                  () => handleTabChange("/Help-center/Selling/How_do_I_publish_games_on_6uo_Gameplace")
                }
                data-mdb-ripple="true"
                data-mdb-ripple-color="dark"
              >
                How do I publish games on 6uo Gameplace?
              </p>
            </li>

            <li className="relative cursor-pointer my-2">
              <p
                className={
                  (url == `/Help-center/Selling/How_do_I_sell_games_on_6uo_Gameplace`)
                  ?
                  "flex items-center text-sm py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 text-ellipsis rounded transition duration-300 ease-in-out border border-teal-500"
                  :
                  "flex items-center text-sm py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 text-ellipsis rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out"
                }
                onClick={
                  () => handleTabChange("/Help-center/Selling/How_do_I_sell_games_on_6uo_Gameplace")
                }
                data-mdb-ripple="true"
                data-mdb-ripple-color="dark"
              >
                How do I sell games on 6uo Gameplace?
              </p>
            </li>

            <li className="relative cursor-pointer my-2">
              <p
                className={
                  (url == `/Help-center/Selling/How_do_I_sell_games_on_6uo_Gameplace`)
                  ?
                  "flex items-center text-sm py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 text-ellipsis rounded transition duration-300 ease-in-out border border-teal-500"
                  :
                  "flex items-center text-sm py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 text-ellipsis rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out"
                }
                onClick={
                  () => handleTabChange("/Help-center/Selling/How_do_I_sell_games_on_6uo_Gameplace")
                }
                data-mdb-ripple="true"
                data-mdb-ripple-color="dark"
              >
                How do I cancel or edit the price of NFT listings?
              </p>
            </li>

        </ul>
    </div>
  )
}
