import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function Selling() {

    const navigate = useNavigate();

    const handleTabChange = (question) => {
        navigate(question);
    }

  return (
    <div className='dark:bg-gray-800 dark:text-gray-200 min-h-[calc(100vh-64px-235px)]'>
        <div className="p-20">
            <h1 classnamename="text-3xl my-10">
                <p>Selling</p>
            </h1>
            <h2 className="text-2xl">

                <a
                    className="hover:underline" 
                    onClick={() => handleTabChange("How_do_I_publish_games_on_6uo_Gameplace")} 
                    alt="how do I publish games on 6uo Gameplace?"
                >
                    <li className="list-none p-3 border-b-2 border-gray-500 cursor-pointer">
                        How do I publish games on 6uo Gameplace?
                    </li>
                </a>

                <a
                    className="hover:underline" 
                    onClick={() => handleTabChange("How_do_I_sell_games_on_6uo_Gameplace")} 
                    alt=""
                >
                    <li className="list-none p-3 border-b-2 border-gray-500 cursor-pointer">
                        How do I sell games on 6uo Gameplace?
                    </li>
                </a>

                <a
                    className="hover:underline" 
                    onClick={() => handleTabChange("How_do_I_cancel_or_edit_my_listings_prices")} 
                    alt=""
                >
                    <li className="list-none p-3 border-b-2 border-gray-500 cursor-pointer">
                        How do I cancel or edit the price of NFT listings?
                    </li>
                </a>

            </h2>
        </div>
    </div>
  )
}
