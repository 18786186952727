import React from 'react'
import SellingPath from '../components/SellingPath';
import SellingSideMenu from '../components/SellingSideMenu';

export default function How_do_I_cancel_or_edit_my_listings_prices() {

    return (
        <div className='flex dark:bg-gray-800 dark:text-gray-200'>

            <SellingSideMenu/>

            <div className='flex flex-col'>
            
                <SellingPath/>

                <div className="m-20 mt-0 inline-block text-lg">

                    <h1 className="text-4xl mt-10 mb-10">
                        <p>How do I cancel or edit the price of NFT listings?</p>
                    </h1>

                    <p>
                        &emsp; 1. Click the Profile button at the top right of the page.
                    </p>

                    <p><img className="my-20 width-full mx-auto" src="/HelpCenter/How_can_I_sign_out_and_connect_to_a_different_wallet/clickProfile.gif"></img></p>

                    <p>
                        &emsp; 2. From the dropdown menu, select Active Listings.
                    </p>

                    <p><img className="my-20 width-full mx-auto" src="/HelpCenter/How_do_I_sell_games_on_6uo_Gameplace/YourCollections.gif
                    "></img></p>

                    <p>To cancel a listing:</p>

                    <p>
                        &emsp; 3. Find the listing game you want to cancel and click the cancel button.
                    </p>

                    <p>
                        &emsp; 4. Comfirm in your wallet.
                    </p>

                    <p><img className="my-20 width-full mx-auto" src="/HelpCenter/How_do_I_sell_games_on_6uo_Gameplace/Wallet.gif"></img></p>
                    
                    <p>To edit the price:</p>

                    <p>
                        &emsp; 3. Find the listing game you want to cancel and click the edit button.
                    </p>

                    <p>
                        &emsp; 4. Set the price in ETH you want to sell at.
                    </p>

                    <p>
                        &emsp; 5. Click the Edit button.
                    </p>

                    <p>
                        &emsp; 6. Comfirm in your wallet.
                    </p>

                </div>
        
            </div>

        </div>
    )
}
