import React from 'react'
import SellingPath from '../components/SellingPath';
import SellingSideMenu from '../components/SellingSideMenu';

export default function How_do_I_sell_games_on_6uo_Gameplace() {

    return (
        <div className='flex dark:bg-gray-800 dark:text-gray-200'>

            <SellingSideMenu/>

            <div className='flex flex-col'>
            
                <SellingPath/>

                <div className="m-20 mt-0 inline-block text-lg">

                    <h1 className="text-4xl mt-10 mb-10">
                        <p>How do I sell games on 6uo Gameplace?</p>
                    </h1>

                    <p className="my-8">
                        Contrary to other methods of digital game purchase, you can sell your access rights on 6uo Gameplace. It sounds familiar, doesn't it? It's just like when you used to buy, sell, and collect CD-ROM console games.
                    </p>

                    <p>Let get start now:</p>

                    <p>
                        &emsp; 1. Click the Profile button at the top right of the page.
                    </p>

                    <p><img className="my-20 width-full mx-auto" src="/HelpCenter/How_can_I_sign_out_and_connect_to_a_different_wallet/clickProfile.gif"></img></p>

                    <p>
                        &emsp; 2. From the dropdown menu, select Your collections.
                    </p>

                    <p><img className="my-20 width-full mx-auto" src="/HelpCenter/How_do_I_sell_games_on_6uo_Gameplace/YourCollections.gif
                    "></img></p>
                    
                    <p>
                        &emsp; 3. Select the game you want to sell.
                    </p>
                    
                    <p><img className="my-20 width-full mx-auto" src="/HelpCenter/How_do_I_sell_games_on_6uo_Gameplace/Sell.gif"></img></p>

                    <p>
                        &emsp; 4. Now, the Sell button will appear next to the Buy button; click it.
                    </p>

                    <p><img className="my-20 width-full mx-auto" src="/HelpCenter/How_do_I_sell_games_on_6uo_Gameplace/Price.gif"></img></p>

                    <p>
                        &emsp; 5. Set the price in ETH you want to sell at.
                    </p>

                    <p><img className="my-20 width-full mx-auto" src="/HelpCenter/How_do_I_sell_games_on_6uo_Gameplace/OwnedItems.gif"></img></p>

                    <p>
                        &emsp; 6. Click the Comfirm button.
                    </p>

                    <p><img className="my-20 width-full mx-auto" src="/HelpCenter/How_do_I_sell_games_on_6uo_Gameplace/Comfirm.gif"></img></p>

                    <p>
                        &emsp; 7. Comfirm in your wallet.
                    </p>

                    <p><img className="my-20 width-full mx-auto" src="/HelpCenter/How_do_I_sell_games_on_6uo_Gameplace/Wallet.gif"></img></p>

                </div>
        
            </div>

        </div>
    )
}
