import React from 'react'

export default function InviteStateModal( { state, wrapperRef } ) {
  return (
    <>
        <div className="justify-center items-end sm:items-center m-3 sm:m-0 flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative sm:my-8 w-full sm:max-w-2xl">
                {/*content start*/}
                

                    

                    <div ref={wrapperRef} className="border-0 rounded-xl shadow-lg relative flex flex-col w-full h-auto bg-white dark:bg-gray-800 outline-none focus:outline-none">

                        {/*header start*/}
                        <div className="flex flex-col items-center justify-center p-5 border-b border-solid border-gray-300 dark:border-gray-700 rounded-t">
                            <h3 className="text-2xl font-semibold dark:text-gray-200">
                                Invite State
                            </h3>
                        </div>
                        {/*header end*/}

                        {/*body*/}
                        <div className="relative p-10 flex flex-col-reverse sm:flex-row justify-center gap-10">
                            <p>{state}</p>
                        </div>
                        {/*body end*/}

                        {/*footer*/}
                        {/* <div className="flex items-center justify-center p-6 border-t border-solid border-gray-300 dark:border-gray-700 rounded-b gap-2.5">
                            <button
                            className="w-36 bg-teal-500 text-white active:bg-teal-700 font-bold uppercase py-3 text-sm rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() => buyHandler(info)}
                            >
                            Buy
                            </button>
                            <button
                            className="w-36 bg-gray-500 text-white active:bg-gray-600 font-bold uppercase text-sm py-3 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() => setShowBuyModal(false)}
                            >
                            Cancel
                            </button>
                        </div> */}
                        {/*footer end*/}

                    </div>

                    
                
                {/*content end*/}

            </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}
