import React from 'react'
import SellingPath from '../components/SellingPath';
import SellingSideMenu from '../components/SellingSideMenu';

export default function How_do_I_publish_games_on_6uo_Gameplace() {

    return (
        <div className='flex dark:bg-gray-800 dark:text-gray-200'>

            <SellingSideMenu/>

            <div className='flex flex-col'>
            
                <SellingPath/>

                <div className="m-20 mt-0 inline-block text-lg">

                    <h1 className="text-4xl mt-10 mb-10">
                        <p>How do I publish games on 6uo Gameplace?</p>
                    </h1>

                    <p className="my-8">
                        If you want to publish and test your games on the 6uo Gameplace testnets, you can use our Sandbox, which functions similarly to the actual 6uo Partner website. You can also upload your game to test its compatibility with the 6uo Gameplace and attempt a free trade.
                    </p>

                    <p>6uo Partner Sandbox:</p>

                    <p className="my-8">
                        &emsp; <a className='text-teal-500 hover:text-teal-600' href='https://sandbox.6uogames.com/'>6uo Games for You!</a>
                    </p>

                    <p><img className="mb-20 width-full mx-auto" src="/HelpCenter/How_do_I_publish_games_on_6uo_Gameplace/sandbox.png"></img></p>

                </div>
        
            </div>

        </div>
    )
}
