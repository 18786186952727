import React from 'react'
import GettingStartedPath from '../components/GettingStartedPath';
import GettingStartedSideMenu from '../components/GettingStartedSideMenu';

export default function What_is_a_crypto_wallet() {

    return (
        <div className='flex dark:bg-gray-800 dark:text-gray-200'>

            
            <GettingStartedSideMenu/>

            <div className='flex flex-col'>

            
                <GettingStartedPath/>

                <div className="m-20 mt-0 inline-block text-lg">

                    <h1 className="text-4xl mt-10 mb-10">
                        <p>What is a crypto wallet?</p>
                    </h1>

                    <p>
                        Crypto wallets hold your private keys, securing and making your crypto and NFTs accessible. In addition, they let you transmit, receive, spend and store your crypto assets.
                    </p>
                    <br/>
                    
                    <p>So, What is a private key?</p>

                    <p>A private key is a string of letters and numbers that, like a password, allows you to access and manage your cryptocurrency funds. Your private keys should be kept as securely as you can.</p>
                    
                    <br/>
                    <p>What is a public address?</p>

                    <p>A public address is a string of numbers and letters used to identify wallets and transfer and receive crypto. Consider it similar to a username or a bank account number.</p>
                    
                    <br/>
                    <p>What are the types of wallets?</p>
                    
                    <p>Hot Wallets :</p>
                    <p>Just like your physical wallet would not contain all of your money, your hot wallet should not have a huge quantity of bitcoin. As a result, you should only save enough cryptocurrencies for purchases and only keep crypto things that you have recently purchased or want to sell. This is your daily wallet, which you can afford to lose.</p>
                    <p>This is the most prevalent sort of wallet, which may take the shape of a browser extension or a mobile app.</p>
                    <div className='my-5 ml-10'>
                        <p>Web :</p>
                        <p>Web wallets may be accessible via web browser extensions, allowing you to connect with the website directly and use your crypto assets.</p>
                    </div>
                    <div className='my-5 ml-10'>
                        <p>Mobile :</p>
                        <p>This type of wallet is in the form of an application that can be downloaded from the Google Play store for Androids or the Apple App Store for iPhones. You can connect to a dApp directly from your mobile or connect to a website by scanning a QR code.</p>
                    </div>
                    
                    <p>Cold Wallet :</p>
                    <p>A cold wallet is one that you cannot quickly access. It might be a hardware wallet, but it could also be a software wallet saved on paper in a safe deposit box or a laptop used only when interacting with your cold wallet. This wallet should hold all of your valuable stuff as well as any substantial sums of cryptocurrency.</p>
                </div>
        
            </div>
        </div>
    )
}
