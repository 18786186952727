import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ethers } from 'ethers';
import axios from 'axios';
import { sepoliaProvider, gameplaceAddress } from '../../config';
import gameplaceContract from '../../contracts/GamePlace.json';
import minterContract from '../../contracts/Minter.json';

function RatingAndCommentModal( {updateRating , wrapperRef} ) {

    const [rating, setRating] = useState(0);

    // const [numOfRating, setNumOfRating] = useState(0);

    const [comment, setComment] = useState();

    const {tokenId} = useParams();

    const [hover, setHover] = useState(0);

    const [account, setAccount] = useState();

    const [auth, setAuth] = useState();

    const [name, setName] = useState();

    const [image, setImage] = useState();

    const [isLoading, setIsLoading] = useState(false);

    const setRatingHandler = () => {
        try {
            fetch(
                `${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/games/${tokenId}/setRating`,
                {
                  body: JSON.stringify({
                    public_address: `${account}`,
                    token: `${auth}`,
                    rating: `${rating}`,
                    comment: `${comment}`,
                  }),
                  headers: {
                    "Content-Type": "application/json",
                  },
                  method: "POST",
                }
              ).then((response) => {
                localStorage.setItem(`${tokenId}rating`, JSON.stringify(rating));
                updateRating();
              });
        } catch (error) {
            console.log(error);
        }
    }

    const getInfo = async () => {
        try {
            setIsLoading(true);
            const providers = new ethers.providers.JsonRpcProvider(sepoliaProvider);
            const gameplace = new ethers.Contract(gameplaceAddress, gameplaceContract.abi, providers)
            const detail = await gameplace.getGameDetail( tokenId );
            const mintercontract = new ethers.Contract(detail[0].mintContract, minterContract.abi, providers);
            const tokenUri = await mintercontract.uri(tokenId);
            const tokenInfo = await axios.get(tokenUri);
            const image = tokenInfo.data.image.replace('ipfs:/', 'https://ipfs.io/ipfs/');
            setName(tokenInfo.data.name);
            setImage(image);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {

        var account;
        try {
            account = JSON.parse(localStorage.getItem("account"));
        } catch (e) {
            account = localStorage.getItem("account");
        }
        setAccount(account);

        var auth;
        try {
            auth = JSON.parse(localStorage.getItem("authToken"));
        } catch (e) {
            auth = localStorage.getItem("authToken");
        }
        setAuth(auth);

        getInfo();

    }, []);

    return (
        
        <>
            <div className="justify-center items-end sm:items-center m-3 sm:m-0 flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative sm:my-8 w-full sm:max-w-2xl">
                    {/*content*/}

                        <div ref={wrapperRef} className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white dark:bg-gray-800 outline-none focus:outline-none">

                            {/*header*/}
                            <div className="flex flex-row items-center justify-center p-5 border-b border-solid border-gray-300 dark:border-gray-700 rounded-t gap-6">
                                { !isLoading ? (
                                    <>
                                        <img src={image} loading="lazy" alt="Game Icon" className="w-16 h-16 object-cover object-center rounded-xl" />
                                        <h3 className="text-3xl font-semibold dark:text-gray-100">{name}</h3>
                                    </>
                                ) : (
                                    <>
                                        <div className='flex justify-center items-center w-16 h-16 bg-gray-300 rounded-xl dark:bg-gray-600 animate-pulse'>
                                            <svg className="w-12 h-12 text-gray-200 dark:text-gray-700" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512"><path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/></svg>
                                        </div>
                                        <div className='w-32 h-10 bg-gray-300 rounded-xl dark:bg-gray-600 animate-pulse'></div>
                                    </>
                                )}
                            </div>
                            {/*header end*/}

                            {/*body*/}
                            <div className="relative p-8 flex flex-col justify-center gap-6">

                            <div className="star-rating flex justify-center">
                                {[...Array(5)].map((star, index) => {
                                    index += 1;
                                    return (
                                    <button
                                        type="button"
                                        key={index}
                                        className={index <= (hover || rating) ? "text-teal-500 h-6 w-6" : "text-gray-500 h-6 w-6"}
                                        onClick={() => setRating(index)}
                                        onMouseEnter={() => setHover(index)}
                                        onMouseLeave={() => setHover(rating)}
                                    >
                                        <span className="star">&#9733;</span>
                                    </button>
                                    );
                                })}
                            </div>

                            <textarea
                                placeholder="Review (optional)"
                                className="border rounded p-4 focus:outline outline-2 outline-teal-500 dark:bg-gray-800 dark:text-gray-100 dark:border-gray-700"
                                value={comment}
                                onChange={e => setComment(e.target.value)}
                            />
                            
                            </div>
                            {/*body end*/}

                            {/*footer*/}
                            <div className="flex items-center justify-center p-6 border-t border-solid border-gray-300 dark:border-gray-700 rounded-b gap-2.5">
                            <button
                                className="w-36 bg-teal-500 text-white active:bg-teal-700 font-bold uppercase py-3 text-sm rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => {setRatingHandler()}}
                            >
                                Comfirm
                            </button>
                            </div>
                            {/*footer end*/}

                        </div>
                    
                    {/*content end*/}

                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>

    )
}

export default RatingAndCommentModal