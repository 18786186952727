
import React from "react";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import LibraryContext from "../../LibraryContext";
// import Icon from "../Icon.js";

export default function FriendList() {
	const [sessionToken, setSessionToken] = useState();
	const [nfts, setNfts] = useState([]);
	const [ownerAccount, setOwnerAccount] = useState();
	const [loadingState, setLoadingState] = useState("not-loaded");
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	var currentUrl = window.location.pathname;

    const [invitationList, setInvitationList] = useState([]);
    const [friendList, setFriendList] = useState([]);

    const [invitationCount, setInvitionCount] = useState(5);
    const [friendCount, setFriendCount] = useState(15);

	const is0Ac = useRef(true);

    let imgList = ['png', 'jpg', 'jpeg', 'gif'];

    // const randomColor = () => {
    //     let color = Math.floor(Math.random()*16777215).toString(16);
    //     console.log('color: ', color);
    //     return color;
    // }

	const getInvitation = async () => {
        try{
            fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/friends/getInvitation`, {
                body: JSON.stringify({
                    owner_address: `${ownerAccount}`,
                    session_token: `${sessionToken}`,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
                })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log(data.invitation_list);
                    setInvitationList(data.invitation_list);
                });
        } catch (error) {
            console.log(error);
        }
    }

	const getFriendList = () => {
		try{
            fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/friends/getFriendList`, {
                body: JSON.stringify({
                    owner_address: `${ownerAccount}`,
                    session_token: `${sessionToken}`,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
                })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setFriendList(data.friend_list);
                });
        } catch (error) {
            console.log(error);
        }
	}

    const acceptHandler = (account) => {
        try{
            fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/friends/accept/${account}`, {
                body: JSON.stringify({
                    owner_address: `${ownerAccount}`,
                    session_token: `${sessionToken}`,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
                })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log(data);
                    getInvitation();
                    getFriendList();
                });
        } catch (error) {
            console.log(error);
        }
    }

    const rejectHandler = (account) => {
        console.log(account);
        try{
            fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/friends/reject/${account}`, {
                body: JSON.stringify({
                    owner_address: `${ownerAccount}`,
                    session_token: `${sessionToken}`,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
                })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log(data);
                    getInvitation();
                    getFriendList();
                });
        } catch (error) {
            console.log(error);
        }
    }

    const onClickInvitation = (address) => {
        navigate(`../${address}`);
    }

	useEffect(() => {
        var account;
        var authToken;
        try {
            account = JSON.parse(localStorage.getItem("account"));
        } catch (e) {
            account = localStorage.getItem("account");
        }
        try {
            authToken = JSON.parse(localStorage.getItem("authToken"));
        } catch (e) {
            authToken = localStorage.getItem("authToken");
        }
        setOwnerAccount(account);
        setSessionToken(authToken);
    },[])

    useEffect(() => {
        if (is0Ac.current) {
            is0Ac.current = false;
            return;
        }
        getFriendList();
		getInvitation();
    },[ownerAccount])


	return (
		<div className="flex flex-col justify-center pb-4 bg-white dark:bg-gray-800">
            <div className="flex flex-col w-full">
                <div className="flex justify-center items-center mb-4 text-xl dark:text-gray-300">Friend Invitations</div>
                <div className="w-full border-collapse text-left text-sm text-gray-500 dark:text-gray-300">
                    
                    <div className="divide-y divide-gray-100 dark:divide-gray-600 border-t border-gray-100 dark:border-gray-700 rounded-lg flex flex-col gap-2">
                        
                        {invitationList !== undefined && invitationList.length > 0 && (invitationList.slice(0, invitationCount).map((list, i) => (

                            <div key={i} className="bg-blue-50 hover:bg-blue-100 dark:bg-gray-500 dark:hover:bg-gray-400 flex flex-row justify-between rounded-lg">
                                <div className="flex gap-3 px-6 py-4 font-normal text-gray-900">
                                    <div className="relative h-10 w-10 cursor-pointer" onClick={() => onClickInvitation(list.wallet_address)}>
                                        {
                                            list.icon ? (
                                                (imgList.includes(list.icon.split('.')[1])) ? (
                                                    <img
                                                        className="h-full w-full rounded-full object-cover object-center"
                                                        src={list.icon}
                                                        alt=""
                                                        
                                                    />
                                                ) : (
                                                    <img
                                                        className="h-full w-full mask mask-hexagon-2 object-cover object-center"
                                                        src={list.icon}
                                                        alt=""
                                                    />
                                                )
                                            ) : (
                                                
                                                <div style={{background: `#${list.wallet_address.slice(2, 8).toString()}`}} className="relative w-10 h-10 overflow-hidden rounded-full">
                                                    <img
                                                        className="h-full w-full rounded-full object-cover object-center"
                                                        src={`/images/chess_icons/${list.wallet_address.match(/\d+$/)%5}.svg`}
                                                        alt=""
                                                    />
                                                    {/* <svg 
                                                        className="absolute w-12 h-12 text-gray-400 -left-1" 
                                                        // fill={"#" + list.wallet_address.slice(2, 8).toString()}
                                                        viewBox="0 0 20 20" 
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path 
                                                            fill-rule="evenodd" 
                                                            d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" 
                                                            clip-rule="evenodd"
                                                        >

                                                        </path>
                                                    </svg> */}
                                                </div>

                                                // <img 
                                                //     className="h-full w-full rounded-full object-cover object-center"
                                                //     src="/images/user_icon.svg"
                                                //     alt=""
                                                // />
                                                // <Icon color={randomColor} />
                                            )
                                        }
                                        <span className="absolute right-0 bottom-0 h-2 w-2 rounded-full bg-green-400 ring ring-white"></span>
                                    </div>
                                    <div 
                                        className="text-sm"
                                    >
                                        <div className="font-medium text-gray-700 cursor-pointer" onClick={() => onClickInvitation(list.wallet_address)}>{list.wallet_address}</div>
                                        
                                    </div>
                                </div>
                                                            
                                <div className="px-6 py-4">
                                    <div className="flex justify-end gap-4">
                                        <a onClick={() => rejectHandler(list.wallet_address)} className='cursor-pointer border border-gray-600 rounded-lg p-2'>
                                            <img src='YesNo/cross.svg' className='h-4 w-4 hover:scale-110'/>
                                        </a>
                                        <a onClick={() => acceptHandler(list.wallet_address)} className='cursor-pointer border border-gray-600 rounded-lg p-2'>
                                            <img src='YesNo/check.svg' className='h-4 w-4 hover:scale-110'/>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        )))}

                    </div>
                </div>
                <div  className="flex justify-end">
                    <button className="border rounded-lg px-3 py-1 my-3 mr-2" onClick={() => setInvitionCount(invitationCount + 5)}>More</button>
                </div>
                {/* <div>{invitationCount}</div> */}
            </div>
            <div className="flex flex-col w-full">
                <div className="flex justify-center items-center mb-4 text-xl dark:text-gray-300">Your Friends</div>

                <div className="border-spacing-2 w-full border-collapse text-left text-sm text-gray-500 dark:text-gray-300">
                    
                    <div className="bourder-white rounded-lg flex flex-col gap-2">
                        
                        {(friendList !== undefined && friendList.length > 0) && (friendList.slice(0, friendCount).map((list, i) => (

                            <div key={i} className="bg-blue-50 hover:bg-blue-100 dark:bg-gray-500 dark:hover:bg-gray-400 rounded-lg">
                                <div className="flex gap-3 px-6 py-4 font-normal text-gray-900 rounded-lg">
                                    <div className="relative h-10 w-10 cursor-pointer" onClick={() => onClickInvitation(list.wallet_address)}>
                                        {
                                            list.icon ? (
                                                (imgList.includes(list.icon.split('.')[1])) ? (
                                                    <img
                                                        className="h-full w-full rounded-full object-cover object-center"
                                                        src={list.icon}
                                                        alt=""
                                                        
                                                    />
                                                ) : (
                                                    <img
                                                        className="w-full h-full object-cover mask mask-hexagon-2 object-center"
                                                        src={list.icon}
                                                        alt=""
                                                        
                                                    />
                                                )
                                            ) : (
                                                
                                                <div style={{background: `#${list.wallet_address.slice(2, 8).toString()}`}} className="relative w-10 h-10 overflow-hidden rounded-full">
                                                    <img
                                                    className="h-full w-full rounded-full object-cover object-center"
                                                    src={`/images/chess_icons/${list.wallet_address.match(/\d+$/)%5}.svg`}
                                                    alt=""
                                                    
                                                    />
                                                    {/* <svg 
                                                        className="absolute w-12 h-12 text-gray-400 -left-1" 
                                                        // fill={"#" + list.wallet_address.slice(2, 8).toString()}
                                                        viewBox="0 0 20 20" 
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path 
                                                            fill-rule="evenodd" 
                                                            d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" 
                                                            clip-rule="evenodd"
                                                        >

                                                        </path>
                                                    </svg> */}
                                                </div>

                                                // <img 
                                                //     className="h-full w-full rounded-full object-cover object-center"
                                                //     src="/images/user_icon.svg"
                                                //     alt=""
                                                // />
                                                // <Icon color={randomColor} />
                                            )
                                        }
                                        
                                        <span className="absolute right-0 bottom-0 h-2 w-2 rounded-full bg-green-400 ring ring-white"></span>
                                    </div>
                                    <div 
                                        className="text-sm"
                                    >
                                        <div className="font-medium text-gray-700 cursor-pointer" onClick={() => onClickInvitation(list.wallet_address)}>{list.wallet_address}</div>
                                    </div>
                                </div>
                            </div>

                        )))}

                    </div>
                </div>
                <div  className="flex justify-end">
                    <button className="border rounded-lg px-3 py-1 my-3 mr-2" onClick={() => setFriendCount(friendCount + 15)}>More</button>
                </div>
            </div>
		</div>
	)
}