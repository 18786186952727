import React from 'react'

function SkeletonSmallPlayer() {
    return (
        <div role="status" className='max-w-screen-2xl px-4 md:px-8 mx-auto pt-24 animate-pulse'>
            <div className="bg-gray-100 dark:bg-gray-700 rounded-lg p-4 sm:p-8 h-[385.5px]">
                <div className="mb-8 md:mb-12">
                    <h2 className="text-gray-800 dark:text-gray-200 text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-6">Your Library</h2>
                </div>
                <div className='relative flex justify-between items-center max-w-screen-2xl px-4 md:px-8 mx-auto'>
                </div>
            </div>
        </div>
    )
}

export default SkeletonSmallPlayer