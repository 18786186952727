import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function Buying() {

    const navigate = useNavigate();

    const handleTabChange = (question) => {
        navigate(question);
    }

  return (
    <div className='dark:bg-gray-800 dark:text-gray-200 min-h-[calc(100vh-64px-235px)]'>
        <div className="p-20">
            <h1 classnamename="text-3xl my-10">
                <p>Buying</p>
            </h1>
            <h2 className="text-2xl">

                <a
                    className="hover:underline" 
                    onClick={() => handleTabChange("How_do_I_buy_games")} 
                    alt="how do i buy games"
                >
                    <li className="list-none p-3 border-b-2 border-gray-500 cursor-pointer">
                        How do I buy games?
                    </li>
                </a>

                

            </h2>
        </div>
    </div>
  )
}
