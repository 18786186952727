import React from 'react'
import SkeletonReviews from './SkeletonReviews'

function SkeletonGame() {
  return (
    <div className='bg-white dark:bg-gray-800 sm:py-8 lg:py-12 min-h-max min-w-[390px]'>
        <div className="max-w-screen-xl px-4 md:px-8 mx-auto dark:bg-gray-800 animate-pulse">
            <div className='grid md:grid-cols-2 gap-8'>
                {/* image start */}
                <div className='grid lg:grid-cols-5 gap-4'>
                    <div className="flex lg:flex-col order-last lg:order-none gap-4">
                        <div className="bg-gray-100 dark:bg-gray-900 rounded-xl overflow-hidden">
                            <div className='w-full h-full object-cover object-center'>
                                <div className='flex justify-center items-center w-full h-full bg-gray-300 rounded-xl dark:bg-gray-600'>
                                    <svg className="w-12 h-12 m-[30px] text-gray-200 dark:text-gray-700" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512"><path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/></svg>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-100 dark:bg-gray-900 rounded-xl overflow-hidden">
                            <div className='w-full h-full object-cover object-center'>
                                <div className='flex justify-center items-center w-full h-full bg-gray-300 rounded-xl dark:bg-gray-600'>
                                    <svg className="w-12 h-12 m-[30px] text-gray-200 dark:text-gray-700" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512"><path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/></svg>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-100 dark:bg-gray-900 rounded-xl overflow-hidden">
                            <div className='w-full h-full object-cover object-center'>
                                <div className='flex justify-center items-center w-full h-full bg-gray-300 rounded-xl dark:bg-gray-600'>
                                    <svg className="w-12 h-12 m-[30px] text-gray-200 dark:text-gray-700" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512"><path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/></svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-4 bg-gray-100 dark:bg-gray-900 rounded-xl overflow-hidden relative">
                        <div className='w-full h-full object-cover object-center'>
                            <div className='flex justify-center items-center w-full h-full bg-gray-300 rounded-xl dark:bg-gray-600'>
                                <svg className="w-12 h-12 m-32 text-gray-200 dark:text-gray-700" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512"><path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/></svg>
                            </div>
                        </div>
                    </div>
                </div>
                {/* image end */}
                {/* info start */}
                <div className="md:py-8">
                    {/* name start */}
                    <div className="mb-2 md:mb-3">
                        <div className="h-[21px] bg-gray-200 rounded-full dark:bg-gray-600 mb-0.5"></div>
                        <div className="h-[36px] w-1/2 bg-gray-200 rounded-full dark:bg-gray-600"></div>
                    </div>
                    {/* name end */}
                    {/* rating start */}
                    <div className="flex items-center gap-3 mb-6 md:mb-10">
                        <div className="h-7 w-36 bg-gray-200 rounded-full dark:bg-gray-600 gap-1 px-2"></div>
                    </div>
                    {/* rating end */}
                    {/* price start */}
                    <div className="mb-4">
                        <div className="flex items-end gap-2">
                            <div className="h-[53px] w-1/2 bg-gray-200 rounded-full dark:bg-gray-600"></div>
                        </div>
                    </div>
                    {/* price end */}
                    {/* button start */}
                    <div className="flex gap-2.5">
                        <div className='w-[92px] h-[48px] bg-gray-200 rounded-xl dark:bg-gray-600'></div>
                        <div className='w-[92px] h-[48px] bg-gray-200 rounded-xl dark:bg-gray-600'></div>
                    </div>
                    {/* button end */}
                </div>
                {/* info end */}
            </div>
            <div className='mt-16 h-[36px]'>
                <div className='w-1/3 h-full mx-auto bg-gray-200 rounded-full dark:bg-gray-600'></div>
            </div>
            <div className='flex justify-center items-center w-2/3 mx-auto mt-5 bg-gray-200 dark:bg-gray-600 h-36 rounded-xl overflow-hidden'></div>
        </div>
        <SkeletonReviews />
    </div>
  )
}

export default SkeletonGame