import React from 'react';
import { ethers } from 'ethers';
import axios from 'axios';
import minterContract from '../../contracts/Minter.json';
import gameplaceContract from '../../contracts/GamePlace.json';
import { useState , useEffect, useContext } from 'react';

import { gameplaceAddress, sepoliaProvider } from '../../config';
import {web3Modal} from "../../connect/Wallet";

import LibraryContext from '../../LibraryContext';
import EditModal from '../Popups/EditModal';

export default function Seller({auth, onLoggedIn}) {
  const [nfts, setNfts] = useState([])
  const [loadingState, setLoadingState] = useState('not-loaded')
  const [overListed, setOverListed] = useState(false)
  const [formInput, updateFormInput] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const [showEditModal, setShowEditModal] = useState(null);

  var authrequest;
  const [library, setLibrary] = useContext(LibraryContext);

  const checkBalanceAndListedItem = async (tokenId) => {
    const provider = await web3Modal.connect();
    await provider.request({ method: 'eth_requestAccounts' });
    const library = new ethers.providers.Web3Provider(provider);
    const signer = library.getSigner()

    const gameplacecontract = new ethers.Contract(gameplaceAddress, gameplaceContract.abi, signer)
    const check = await gameplacecontract.checkBalanceAndListedItem( tokenId )

    setOverListed(check)
  }

  async function loadNFTs() {
    setIsLoading(true)

    const provider = await web3Modal.connect();
    await provider.request({ method: 'eth_requestAccounts' });
    const library = new ethers.providers.Web3Provider(provider);
    
    const signer = library.getSigner()
    const providers = new ethers.providers.JsonRpcProvider(sepoliaProvider)

    const gameplacecontract = new ethers.Contract(gameplaceAddress, gameplaceContract.abi, signer)
    console.log("Initialize balance")
    const getBalance = await gameplacecontract.getOnSaleBalance()
    console.log("getting balance... plz wait")
    console.log(getBalance)
    
    const items = await Promise.all(getBalance.map(async i => {
      const mintercontract = new ethers.Contract(i.mintContract, minterContract.abi, providers)
      const tokenUri = await mintercontract.uri(i.tokenId)
      console.log(tokenUri)

      const meta = await axios.get(tokenUri)
      console.log('meta: ', meta)
      const image = meta.data.image.replace('ipfs:/', 'https://ipfs.io/ipfs/')

      let price = ethers.utils.formatUnits(i.price.toString(), 'ether')
      console.log('price: ', price)
      let item = {
        ID: i.id,
        contract: i.minterContract,
        tokenId: i.tokenId.toNumber(),
        creator: i.creator,
        seller: i.seller,
        price: price,
        amount: i.amounts.toNumber(),
        totoalamount: i.totalamounts.toNumber(),
        image: image,
        name: meta.data.name,
        description: meta.data.description,
      }
      checkBalanceAndListedItem(i.tokenId)
      return item
    }))
    console.log('items: ', items)
    setNfts(items)
    setLoadingState('loaded')
    setIsLoading(false)
  }

  const close = () => {
    setShowEditModal(null);
    loadNFTs();
  };

  const cancelHandler = async(nft) => {
    
    const signer = library.getSigner()

    const gameplacecontract = new ethers.Contract(gameplaceAddress, gameplaceContract.abi, signer)
    console.log('Initialize removement')
    
    const rm = await gameplacecontract.removeListedSale(nft.ID)
    console.log('removing... plz wait...')
    await rm.wait()
    console.log('removed')
    loadNFTs()
  }

  useEffect(() => {
    loadNFTs();
  }, [])

  const warning = () => {
    return (
      <p>!!! one or more are already sold on other platform !!!</p>
    )
  }

  if (loadingState === 'loaded' && !nfts.length) return (
    <table className="w-full table-fixed border-separate border border-slate-600 rounded-xl">
      <thead>
        <tr>
          {/* <th className="px-4 py-2 dark:text-gray-200 border-b border-slate-600"></th> */}
          <th className="px-4 py-2 dark:text-gray-200 border-b border-slate-600">Item</th>
          <th className="px-4 py-2 dark:text-gray-200 border-b border-slate-600">Description</th>
          <th className="px-4 py-2 dark:text-gray-200 border-b border-slate-600">Price</th>
          <th className="px-4 py-2 dark:text-gray-200 border-b border-slate-600">Edit</th>
          <th className="px-4 py-2 dark:text-gray-200 border-b border-slate-600">Cancel</th>
        </tr>
      </thead>
      <tbody className="w-full justify-center items-center">
        <th></th><th></th>
          <th className='h-16 text-lg dark:text-white'>No items found.</th>
        <th></th><th></th>
      </tbody>
    </table>
  )

  if (isLoading == true) return (
    <div className="animate-pulse border border-white dark:border-gray-500 bg-gray-400 dark:bg-gray-600 rounded-xl">
      <div className="h-[38px] bg-gray-200 dark:bg-gray-800 rounded-t-xl"></div>
      <div className="h-[64px] bg-gray-300 dark:bg-gray-700"></div>
      <div className="h-[64px] bg-gray-200 dark:bg-gray-800 rounded-b-xl"></div>
    </div>
  )

  return (
    <>
      <table className="w-full table-auto mx-auto border-separate border border-slate-600 rounded-xl">
        <thead>
          <tr>
            <th className="px-4 py-2 dark:text-gray-200 border-b border-slate-600"></th>
            <th className="px-4 py-2 dark:text-gray-200 border-b border-slate-600 hidden sm:table-cell">Item</th>
            <th className="px-4 py-2 dark:text-gray-200 border-b border-slate-600 hidden md:table-cell">Description</th>
            <th className="px-4 py-2 dark:text-gray-200 border-b border-slate-600 hidden sm:table-cell">Price</th>
            <th className="px-4 py-2 dark:text-gray-200 border-b border-slate-600">Edit</th>
            <th className="px-4 py-2 dark:text-gray-200 border-b border-slate-600">Cancel</th>
          </tr>
        </thead>
        <tbody>
          {nfts.map((nft, i) => (
            <tr key={i}>
              <td className="px-4 py-2">
                <img src={nft.image} className="rounded h-10 w-10 overflow-hidden" style={{ maxWidth: '100px' }} />
              </td>
              <td className="px-4 py-2 hidden sm:table-cell">
                <span className="px-3 py-1 text-sm font-semibold text-gray-700 dark:text-gray-300 mr-2 inline-block">{nft.name}</span>
              </td>
              <td className="px-4 py-2 hidden md:table-cell">
                <span className="px-3 py-1 text-sm font-semibold text-gray-700 dark:text-gray-300 mr-2 inline-block">{nft.description}</span>
              </td>
              <td className="px-4 py-2 hidden sm:table-cell">
                <span className="px-3 py-1 text-sm font-semibold text-gray-700 dark:text-gray-300 mr-2 inline-block">{nft.price} ETH</span>
              </td>
              <td className="px-4 py-2">
                {/* <input type="text" onChange={e => setFormInput(e.target.value)} /> */}
                <button className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded" onClick={() => setShowEditModal(nft)}>Edit</button>
              </td>
              <td className="px-4 py-2">
                <button className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded" onClick={() => cancelHandler(nft)}>Cancel</button>
              </td>
            </tr>
          ))}
          {overListed && warning()}
        </tbody>
      </table>
      {showEditModal && (<EditModal nft={showEditModal} library={library} close={close}/>)}
    </>
  )
}
