import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function GettingStartedSideMenu() {
  function getCurrentURL () {
    return window.location.pathname
  }
  
  const url = getCurrentURL()

  const navigate = useNavigate();

  const handleTabChange = (question) => {
      navigate(question);
  }

  return (
    <div className="w-60 shadow-md bg-white dark:bg-gray-800 px-1 pl-10 py-20 Absolute hidden md:block">
        <ul className="relative">
            <li className="">
              <p className="flex items-center text-xl py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 whitespace-nowrap">Articles in this section</p>
            </li>

            <li className="relative cursor-pointer my-2">
              <p
                className={
                  (url == "/Help-center/Getting_Started/How_to_create_a_6uo_Gameplace_account")
                  ?
                  "flex items-center text-sm py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 text-ellipsis rounded transition duration-300 ease-in-out border border-teal-500"
                  : 
                  "flex items-center text-sm py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 text-ellipsis rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out"
                }
                onClick={
                  () => handleTabChange("/Help-center/Getting_Started/How_to_create_a_6uo_Gameplace_account")
                }
                alt="how to create a 6uo NFT account"
                data-mdb-ripple="true"
                data-mdb-ripple-color="dark"
              >
                How do I create a 6uo Gameplace account?
              </p>
            </li>
            
            <li className="relative cursor-pointer my-2">
              <p
                className={
                  (url == "/Help-center/Getting_Started/What_crypto_wallets_can_I_use_with_6uo_Gameplace")
                  ?
                  "flex items-center text-sm py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 text-ellipsis rounded transition duration-300 ease-in-out border border-teal-500"
                  :
                  "flex items-center text-sm py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 text-ellipsis rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out"
                }
                onClick={
                  () => handleTabChange("/Help-center/Getting_Started/What_crypto_wallets_can_I_use_with_6uo_Gameplace")
                }
                alt="what crypto wallets can I use with 6uo Gameplace?"
                data-mdb-ripple="true"
                data-mdb-ripple-color="dark"
              >
                What crypto wallets can I use with 6uo Gameplace?
              </p>
            </li>

            <li className="relative cursor-pointer my-2">
              <p
                className={
                  (url == `/Help-center/Getting_Started/What_are_the_key_terms_to_understand_when_it_comes_to_NFTs_and_Web3`)
                  ?
                  "flex items-center text-sm py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 text-ellipsis rounded transition duration-300 ease-in-out border border-teal-500"
                  :
                  "flex items-center text-sm py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 text-ellipsis rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out"
                }
                onClick={
                  () => handleTabChange("/Help-center/Getting_Started/What_are_the_key_terms_to_understand_when_it_comes_to_NFTs_and_Web3")
                }
                alt="what are the key terms to understand when it comes to NFTs and Web3?"
                data-mdb-ripple="true"
                data-mdb-ripple-color="dark"
              >
                What are the key terms to understand when it comes to NFTs and Web3?
              </p>
            </li>

            <li className="relative cursor-pointer my-2">
              <p
                className={
                  (url == `/Help-center/Getting_Started/What_blockchains_are_available_on_6uo_Gameplace`)
                  ?
                  "flex items-center text-sm py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 text-ellipsis rounded transition duration-300 ease-in-out border border-teal-500"
                  :
                  "flex items-center text-sm py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 text-ellipsis rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out"
                }
                onClick={
                  () => handleTabChange("/Help-center/Getting_Started/What_blockchains_are_available_on_6uo_Gameplace")
                }
                alt="what blockchains are available on 6uo Gameplace?"
                data-mdb-ripple="true"
                data-mdb-ripple-color="dark"
              >
                What blockchains are available on 6uo Gameplace?
              </p>
            </li>

            <li className="relative cursor-pointer my-2">
              <p
                className={
                  (url == `/Help-center/Getting_Started/How_do_I_purchase_cryptocurrencies`)
                  ?
                  "flex items-center text-sm py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 text-ellipsis rounded transition duration-300 ease-in-out border border-teal-500"
                  :
                  "flex items-center text-sm py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 text-ellipsis rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out"
                }
                onClick={
                  () => handleTabChange("/Help-center/Getting_Started/How_do_I_purchase_cryptocurrencies")
                }
                data-mdb-ripple="true"
                data-mdb-ripple-color="dark"
              >
                How do I purchase cryptocurrencies?
              </p>
            </li>

            <li className="relative cursor-pointer my-2">
              <p
                className={
                  (url == `/Help-center/Getting_Started/What_are_service_fees_and_royalties`)
                  ?
                  "flex items-center text-sm py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 text-ellipsis rounded transition duration-300 ease-in-out border border-teal-500"
                  :
                  "flex items-center text-sm py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 text-ellipsis rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out"
                }
                onClick={
                  () => handleTabChange("/Help-center/Getting_Started/What_are_service_fees_and_royalties")
                }
                data-mdb-ripple="true"
                data-mdb-ripple-color="dark"
              >
                What are service fees and royalties?
              </p>
            </li>

            <li className="relative cursor-pointer my-2">
              <p
                className={
                  (url == `/Help-center/Getting_Started/What_is_a_crypto_wallet`)
                  ?
                  "flex items-center text-sm py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 text-ellipsis rounded transition duration-300 ease-in-out border border-teal-500"
                  :
                  "flex items-center text-sm py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 text-ellipsis rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out"
                }
                onClick={
                  () => handleTabChange("/Help-center/Getting_Started/What_is_a_crypto_wallet")
                }
                data-mdb-ripple="true"
                data-mdb-ripple-color="dark"
              >
                What is a crypto wallet?
              </p>
            </li>

            <li className="relative cursor-pointer my-2">
              <p
                className={
                  (url == `/Help-center/Getting_Started/How_to_add_Polygon_Network_in_my_wallet`)
                  ?
                  "flex items-center text-sm py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 text-ellipsis rounded transition duration-300 ease-in-out border border-teal-500"
                  :
                  "flex items-center text-sm py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 text-ellipsis rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out"
                }
                onClick={
                  () => handleTabChange("/Help-center/Getting_Started/How_to_add_Polygon_Network_in_my_wallet")
                }
                data-mdb-ripple="true"
                data-mdb-ripple-color="dark"
              >
                How to add Polygon Network in my wallet?
              </p>
            </li>

            <li className="relative cursor-pointer my-2">
              <p
                className={
                  (url == `/Help-center/Getting_Started/How_can_I_sign_out_and_connect_to_a_different_wallet`)
                  ?
                  "flex items-center text-sm py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 text-ellipsis rounded transition duration-300 ease-in-out border border-teal-500"
                  :
                  "flex items-center text-sm py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 text-ellipsis rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out"
                }
                onClick={
                  () => handleTabChange("/Help-center/Getting_Started/How_can_I_sign_out_and_connect_to_a_different_wallet")
                }
                data-mdb-ripple="true"
                data-mdb-ripple-color="dark"
              >
                How can I sign out and connect to a different wallet?
              </p>
            </li>
            
            {/* <li className="relative cursor-pointer my-2">
              <p
                className={
                  (url == `/Help-center/Getting_Started/`)
                  ?
                  "flex items-center text-sm py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 text-ellipsis rounded transition duration-300 ease-in-out border border-teal-500"
                  :
                  "flex items-center text-sm py-4 px-2 h-16 overflow-hidden text-gray-700 dark:text-gray-200 text-ellipsis rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out"
                }
                onClick={
                  () => handleTabChange("/Help-center/Getting_Started/")
                }
                data-mdb-ripple="true"
                data-mdb-ripple-color="dark"
              >
                
              </p>
            </li> */}
        </ul>
    </div>
  )
}
