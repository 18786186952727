import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Unity, useUnityContext } from "react-unity-webgl";

function Gaming() {
  const { game_id } = useParams();
  const params = new URLSearchParams(window.location.pathname);

  var account;
  var auth;
  var gamingToken;

  try {
    account = JSON.parse(localStorage.getItem("account"));
  } catch (e) {
    account = localStorage.getItem("account");
  }

  try {
    auth = JSON.parse(localStorage.getItem("authToken"));
  } catch (e) {
    auth = localStorage.getItem("authToken");
  }

  try {
    gamingToken = JSON.parse(localStorage.getItem("gamingToken"));
  } catch (e) {
    gamingToken = localStorage.getItem("gamingToken");
  }

  async function getGamingToken(account) {
    try {
      await fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/games/play`, {
        body: JSON.stringify({
          public_address: `${account}`,
          session_token: `${auth}`,
        }),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      })
        .then((response) => {
          if (response.status !== 200) {
            throw new Error("HTTP error " + response.status);
          }
          return response.json();
        })
        .then((data) => {
          gamingToken = data.token;
        });
    } catch (error) {
      console.log("error: ", error);
    }
  }

  useEffect(() => {
    // getGamingToken(account);
  }, []);

  const { unityProvider } = useUnityContext({
    loaderUrl: `${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/games/${game_id}/loaderUrl/?public_address=${account}&token=${gamingToken}`,
    dataUrl: `${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/games/${game_id}/dataUrl/?public_address=${account}&token=${gamingToken}`,
    frameworkUrl: `${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/games/${game_id}/frameworkUrl/?public_address=${account}&token=${gamingToken}`,
    codeUrl: `${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/games/${game_id}/codeUrl/?public_address=${account}&token=${gamingToken}`,
  });

  return (
    <Unity
      unityProvider={unityProvider}
      className="game_canvas h-screen w-screen"
    />
  );
}

export default Gaming;