import React from 'react'
import GettingStartedPath from '../components/GettingStartedPath';
import GettingStartedSideMenu from '../components/GettingStartedSideMenu';

export default function What_blockchains_are_available_on_6uo_Gameplace() {

    return (
        <div className='flex dark:bg-gray-800 dark:text-gray-200'>

            
            <GettingStartedSideMenu/>

            <div className='flex flex-col'>

            
                <GettingStartedPath/>

                <div className="m-20 mt-0 inline-block text-lg">

                    <h1 className="text-4xl mt-10 mb-10">
                        <p>What blockchains are available on 6uo Gameplace?</p>
                    </h1>

                    <table className='table-auto w-full rounded-xl bg-gray-700'>
                        <thead className='text-md font-semibold uppercase'>
                            <tr>
                                <th className='p-2 whitespace-nowrap '>
                                    <div className='font-semibold text-center'>Blockchain</div>
                                </th>
                                <th className='p-2 whitespace-nowrap '>
                                    <div className='font-semibold text-center'>Token</div>
                                </th>
                                <th className='p-2 whitespace-nowrap '>
                                    <div className='font-semibold text-center'>Website</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className='text-sm'>
                            <tr>
                                <td className="p-2 whitespace-nowrap">
                                    <div className="text-center">Ethereum</div>
                                </td>
                                <td className="p-2 whitespace-nowrap">
                                    <div className="text-center">ETH</div>
                                </td>
                                <td className="p-2 whitespace-nowrap">
                                    <div className="text-center">
                                        <a className='underline underline-offset-1' href='https://ethereum.org/'>
                                            ethereum.org
                                        </a>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="p-2 whitespace-nowrap">
                                    <div className="text-center">Polygon</div>
                                </td>
                                <td className="p-2 whitespace-nowrap">
                                    <div className="text-center">MATIC</div>
                                </td>
                                <td className="p-2 whitespace-nowrap">
                                    <div className="text-center">
                                        <a className='underline underline-offset-1' href='https://polygon.technology/'>
                                            polygon.technology
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
        
            </div>
        </div>
    )
}
