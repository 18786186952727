import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function GettingStarted() {

    const navigate = useNavigate();

    const handleTabChange = (question) => {
        navigate(question);
    }

  return (
    <div className='dark:bg-gray-800 dark:text-gray-200 min-h-[calc(100vh-64px-235px)]'>
        <div className="p-20">
            <h1 classnamename="text-3xl my-10">
                <p>Getting Started</p>
            </h1>
            <h2 className="text-2xl">
                
                <a className="hover:underline" onClick={() => handleTabChange("How_to_create_a_6uo_Gameplace_account")} alt="how to create a 6uo NFT account">
                    <li className="list-none p-3 border-b-2 border-gray-500 cursor-pointer">
                        How do I create a 6uo NFT account?
                    </li>
                </a>

                <a className="hover:underline" onClick={() => handleTabChange("What_crypto_wallets_can_I_use_with_6uo_Gameplace")} alt="what crypto wallets can I use with 6uo Gameplce">
                    <li className="list-none p-3 border-b-2 border-gray-500 cursor-pointer">
                        What crypto wallets can I use with 6uo Gameplce?
                    </li>
                </a>

                <a className="hover:underline" onClick={() => handleTabChange("What_are_the_key_terms_to_understand_when_it_comes_to_NFTs_and_Web3")} alt="what crypto wallets can I use with 6uo Gameplce">
                    <li className="list-none p-3 border-b-2 border-gray-500 cursor-pointer">
                        What are the key terms to understand when it comes to NFTs and Web3?
                    </li>
                </a>

                <a className="hover:underline" onClick={() => handleTabChange("What_blockchains_are_available_on_6uo_Gameplace")} alt="what crypto wallets can I use with 6uo Gameplce">
                    <li className="list-none p-3 border-b-2 border-gray-500 cursor-pointer">
                        What blockchains are available on 6uo Gameplace?
                    </li>
                </a>

                <a
                    className="hover:underline" 
                    onClick={() => handleTabChange("How_do_I_purchase_cryptocurrencies")} 
                    alt="what crypto wallets can I use with 6uo Gameplce"
                >
                    <li className="list-none p-3 border-b-2 border-gray-500 cursor-pointer">
                        How do I purchase cryptocurrencies?
                    </li>
                </a>

                <a
                    className="hover:underline" 
                    onClick={() => handleTabChange("What_are_service_fees_and_royalties")} 
                    alt="what are service fees and royalties"
                >
                    <li className="list-none p-3 border-b-2 border-gray-500 cursor-pointer">
                        What are service fees and royalties?
                    </li>
                </a>

                <a
                    className="hover:underline" 
                    onClick={() => handleTabChange("What_is_a_crypto_wallet")} 
                    alt="what is a crypto wallet"
                >
                    <li className="list-none p-3 border-b-2 border-gray-500 cursor-pointer">
                        What is a crypto wallet?
                    </li>
                </a>

                <a
                    className="hover:underline" 
                    onClick={() => handleTabChange("How_to_add_Polygon_Network_in_my_wallet")} 
                    alt=""
                >
                    <li className="list-none p-3 border-b-2 border-gray-500 cursor-pointer">
                        How to add Polygon Network in my wallet?
                    </li>
                </a>

                <a
                    className="hover:underline" 
                    onClick={() => handleTabChange("How_can_I_sign_out_and_connect_to_a_different_wallet")} 
                    alt=""
                >
                    <li className="list-none p-3 border-b-2 border-gray-500 cursor-pointer">
                        How can I sign out and connect to a different wallet?
                    </li>
                </a>

                {/* <a
                    className="hover:underline" 
                    onClick={() => handleTabChange("")} 
                    alt=""
                >
                    <li className="list-none p-3 border-b-2 border-gray-500 cursor-pointer">
                        
                    </li>
                </a> */}

            </h2>
        </div>
    </div>
  )
}
