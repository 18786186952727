import React, { useEffect , useState , useRef } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import Noty from '../Noty';
import { useNavigate } from 'react-router-dom';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function NotificationModal() {
    const navigate = useNavigate();

    const [ownerAccount, setOwnerAccount] = useState();
    const [sessionToken, setSessionToken] = useState();

    const is0Ac = useRef(true);

    const [notificationCount, setNotificationCount] = useState(0);

    const onClickHandler = () => {
        navigate('notification');
    }

    useEffect(() => {
        var account;
        var authToken;
        try {
            account = JSON.parse(localStorage.getItem("account"));
        } catch (e) {
            account = localStorage.getItem("account");
        }
        try {
            authToken = JSON.parse(localStorage.getItem("authToken"));
        } catch (e) {
            authToken = localStorage.getItem("authToken");
        }
        setOwnerAccount(account);
        setSessionToken(authToken);
    },[])

    const getNotification = () => {
        try {
            fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/notification/getNotification`, {
                body: JSON.stringify({
                    owner_address: `${ownerAccount}`,
                    session_token: `${sessionToken}`,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                var n = 0;
                // console.log('data of notification:', data.notification_list);
                for (var i = 0; i < data.notification_list.length; i++) {
                    if (data.notification_list[i].seen == 0) {
                        n++;
                    }
                }
                setNotificationCount(n);
            });
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (is0Ac.current) {
            is0Ac.current = false;
            return;
        }
        getNotification();
    },[ownerAccount])

  return (
    <div className='ml-3 relative'>
        <button onClick={() => onClickHandler()} className='ml-3 bg-white dark:bg-gray-800 p-1 rounded-lg text-gray-500 hover:text-gray-700 hover:dark:text-gray-300 dark:hover:ring-gray-700 hover:scale-110'>
            <span className="sr-only">Open notifications</span>
            <Noty width={"30px"} color={"currentColor"} count={notificationCount} />
        </button>
    </div>
    // <Menu as="div" className="ml-3 relative">
    //     <div>
    //         <Menu.Button className="ml-3 bg-white dark:bg-gray-800 p-1 rounded-lg text-gray-500 hover:text-gray-700 hover:dark:text-gray-300 dark:hover:ring-gray-700 hover:scale-110">
    //             <span className="sr-only">Open notifications</span>
    //             <Noty width={"30px"} color={"currentColor"} count={10} />
    //         </Menu.Button>
    //     </div>
    //     <Transition
    //         as={Fragment}
    //         enter="transition ease-out duration-100"
    //         enterFrom="transform opacity-0 scale-95"
    //         enterTo="transform opacity-100 scale-100"
    //         leave="transition ease-in duration-75"
    //         leaveFrom="transform opacity-100 scale-100"
    //         leaveTo="transform opacity-0 scale-95"
    //     >
    //         <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none">
    //             <Menu.Item>
    //                 {({ active }) => (
    //                     <a
    //                         href="/notification?tab="
    //                         className={classNames(active ? 'bg-gray-100 dark:bg-gray-800' : '', 'block px-4 py-2 text-sm text-gray-700 dark:text-gray-300')}
    //                     >
    //                         1
    //                     </a>
    //                 )}
    //             </Menu.Item>
    //             <Menu.Item>
    //                 {({ active }) => (
    //                     <a
    //                         href="/notification?tab="
    //                         className={classNames(active ? 'bg-gray-100 dark:bg-gray-800' : '', 'block px-4 py-2 text-sm text-gray-700 dark:text-gray-300')}
    //                     >
    //                         2
    //                     </a>
    //                 )}
    //             </Menu.Item>
    //             <Menu.Item>
    //                 {({ active }) => (
    //                     <a
    //                         href="/notification?tab="
    //                         className={classNames(active ? 'bg-gray-100 dark:bg-gray-800' : '', 'block px-4 py-2 text-sm text-gray-700 dark:text-gray-300')}
    //                     >
    //                         3
    //                     </a>
    //                 )}
    //             </Menu.Item>
    //             <Menu.Item>
    //                 {({ active }) => (
    //                     <a
    //                         href="/notification?tab="
    //                         className={classNames(active ? 'bg-gray-100 dark:bg-gray-800' : '', 'block px-4 py-2 text-sm text-gray-700 dark:text-gray-300')}
    //                     >
    //                         4
    //                     </a>
    //                 )}
    //             </Menu.Item>
    //         </Menu.Items>
    //     </Transition>
    // </Menu>
  )
}
