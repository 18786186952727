import React from 'react'
import GettingStartedPath from '../components/GettingStartedPath';
import GettingStartedSideMenu from '../components/GettingStartedSideMenu';

export default function How_do_I_purchase_cryptocurrencies() {

    return (
        <div className='flex dark:bg-gray-800 dark:text-gray-200'>

            
            <GettingStartedSideMenu/>

            <div className='flex flex-col'>

            
                <GettingStartedPath/>

                <div className="m-20 mt-0 inline-block text-lg">

                    <h1 className="text-4xl mt-10 mb-10">
                        <p>How do I purchase cryptocurrencies?</p>
                    </h1>

                    <p className="my-8">
                        The Ethereum network's native currency is ETH, while Polygon's native token is MATIC.
                    </p>

                    <p>Ways to buy cryptocurrency:</p>

                    <br/>

                    <p className="my-8 text-xl font-bold">
                        1.  Purchased through various exchanges
                    </p>

                    <p>The best option for the majority of people is to choose a trustworthy cryptocurrency exchange, such as Coinbase or Binance, that allows cash purchases of cryptocurrencies.</p>

                    <p>Use the wallet address you copied to transfer cryptocurrencies from exchanges to your crypto wallet.</p>

                    <p className='pb-6'>You may also examine the FAQs listed below for each exchange.</p>
                    
                    <a href='https://www.binance.com/en/feed/post/76476'>
                        <p className='text-teal-600 dark:text-teal-400 hover:text-teal-500'>
                            &emsp; Transfer from Binance
                        </p>
                    </a>
                    <br/>
                    <a href='https://help.coinbase.com/en/coinbase/trading-and-funding/cryptocurrency-trading-pairs/how-to-send-and-receive-cryptocurrency'>
                        <p className='text-teal-600 dark:text-teal-400 hover:text-teal-500'>
                            &emsp; Transfer from Coinbase
                        </p>
                    </a>
                    
                    <p className="my-8 texl-xl font-bold">
                        2.  Purchase Ethereum directly from crypto wallet
                    </p>

                    <p>The majority of wallet providers (including MetaMask) allow customers to buy cryptocurrencies straight from their wallet.</p>
                    
                    <p className='pb-6'>You may also examine the FAQs listed below for each wallet.</p>

                    <a href='https://metamask.zendesk.com/hc/en-us/articles/360058239311-Directly-buying-tokens-with-on-ramps-in-MetaMask'>
                        <p className='text-teal-600 dark:text-teal-400 hover:text-teal-500'> 
                            &emsp; How to buy crypto in MetaMask
                        </p>
                    </a>
                    <br/>
                    <a href='https://help.coinbase.com/en/wallet/managing-account/buy-crypto'>
                        <p className='text-teal-600 dark:text-teal-400 hover:text-teal-500'> 
                            &emsp; How to buy crypto in Coinbase
                        </p>
                    </a>

                </div>
        
            </div>
        </div>
    )
}
