import React from 'react';
import { ethers } from 'ethers';
import { gameplaceAddress } from '../../config';
import gameplaceContract from '../../contracts/GamePlace.json';
import { web3Modal } from '../../connect/Wallet';
import { truncateAddress } from '../../connect/utils';

function BuyModal( {loadingModal, setLoadingModal, close, setShowBuyModal, wrapperRef, info} ) {

    const buyHandler = async (info) => {
        try {
            setLoadingModal(1);
            const provider = await web3Modal.connect()
            const library = new ethers.providers.Web3Provider(provider)
            const signer = library.getSigner()
            const gameplace = new ethers.Contract(gameplaceAddress, gameplaceContract.abi, signer)
            const detail = await gameplace.getGameDetail( info.id );

            console.log(parseFloat(info.price));
            const price = parseFloat(info.price)
            console.log((price * 1025000 / 1000).toString());
            const totalPrice = ethers.utils.parseUnits((price * 1000000000).toString(), 'gwei')
            console.log("Initialize payment")
            const transaction = await gameplace.trade(detail[0].mintContract, info.id, 1, { value: totalPrice })
            console.log("transfering... plz wait")
            setLoadingModal(2);
            await transaction.wait()
            // console.log(`transfered, see mint: https://goerli.etherscan.io/tx/${transaction.hash}`)
            setLoadingModal(3);
        } catch (err) {
          console.log(err)
        }
    }

    return (
        <>
            <div className="justify-center items-end sm:items-center m-3 sm:m-0 flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative sm:my-8 w-full sm:max-w-2xl">
                    {/*content start*/}
                    { loadingModal != 0 ? 
                        (
                            (loadingModal == 1) ? (

                                <div className='border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white dark:bg-gray-800 outline-none focus:outline-none h-[665px] sm:h-[490px]'>

                                    <div className='flex flex-col items-center justify-center p-5 border-b border-solid border-gray-300 dark:border-gray-700 rounded-t'>
                                    <h3 className='text-2xl font-semibold dark:text-gray-200 text-center'>
                                        Plz Comfirm in your wallet
                                    </h3>
                                    </div>

                                    <div className="flex flex-row justify-center items-center bg-white dark:bg-gray-800 border-solid border-gray-300 rounded-xl gap-2.5 p-6 h-full">
                                    <svg role="status" className="inline w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-teal-600 rounded-b" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                    </svg>
                                    </div>

                                    <div className="flex items-center justify-center p-6 border-t border-solid border-gray-300 dark:border-gray-700 rounded-b gap-2.5">
                                    <button
                                        className="w-36 bg-gray-500 text-white active:bg-gray-600 font-bold uppercase text-sm py-3 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={close}
                                    >
                                        Cancel
                                    </button>
                                    </div>

                                </div>

                            ) : (

                                (loadingModal == 2) ? (

                                    <div className='border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white dark:bg-gray-800 outline-none focus:outline-none h-[665px] sm:h-[490px]'>

                                    <div className='flex flex-col items-center justify-center p-5 border-b border-solid border-gray-300 dark:border-gray-700 rounded-t'>
                                        <h3 className='text-2xl font-semibold dark:text-gray-200 text-center'>
                                        Transfering...
                                        </h3>
                                    </div>

                                    <div className="flex justify-center items-center bg-white dark:bg-gray-800 border-solid border-gray-300 rounded-xl gap-2.5 p-6 h-full">
                                        <svg role="status" className="inline w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-teal-600 rounded-b" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                        </svg>
                                    </div>

                                    </div>

                                ) : (
                                    <div className='border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white dark:bg-gray-800 outline-none focus:outline-none h-[665px] sm:h-[490px]'>

                                    <div className='flex flex-col items-center justify-center p-5 border-b border-solid border-gray-300 dark:border-gray-700 rounded-t'>
                                        <h3 className='text-2xl font-semibold dark:text-gray-200 text-center'>
                                        Complete!
                                        </h3>
                                    </div>

                                    <div className="flex justify-center items-center bg-white dark:bg-gray-800 border-solid border-gray-300 dark:border-gray-700 rounded-xl gap-2.5 p-6 h-full">
                                        <img src='/images/correct.png' alt="Game image" className="h-8 w-8"/>
                                        <p className='dark:text-gray-300'>Your purchase have completed, you can check it in your collections</p>
                                    </div>

                                    <div className="flex items-end justify-end p-6 border-t border-solid border-gray-300 rounded-b gap-2.5">
                                        <button
                                        className="w-36 bg-gray-500 text-white active:bg-gray-600 font-bold uppercase text-sm py-3 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={close}
                                        >
                                        Comfirm
                                        </button>
                                    </div>

                                    </div>
                                )

                            )

                        ) : (

                            <div ref={wrapperRef} className="border-0 rounded-xl shadow-lg relative flex flex-col w-full h-auto bg-white dark:bg-gray-800 outline-none focus:outline-none">

                            {/*header start*/}
                            <div className="flex flex-col items-center justify-center p-5 border-b border-solid border-gray-300 dark:border-gray-700 rounded-t">
                                <h3 className="text-2xl font-semibold dark:text-gray-200">
                                Checkout
                                </h3>
                            </div>
                            {/*header end*/}

                            {/*body*/}
                            <div className="relative p-10 flex flex-col-reverse sm:flex-row justify-center gap-10">
                                <div className="flex flex-col">
                                    <h3 className="text-2xl font-semibold text-start pt-2 dark:text-gray-200">{info.name}</h3>
                                    <h5 className="text-sm font-light text-start pt-2 dark:text-gray-200">{info.creator ? truncateAddress(info.creator) : "loading..."}</h5>
                                    <div className="flex justify-between flex-row-2 gap-3 mt-2">
                                        <div className='flex flex-col row-span-1 items-end gap-2 w-1/2'>
                                            <p className='text-gray-600 pt-2 dark:text-gray-400'>Price:</p>
                                            <p className='text-gray-600 dark:text-gray-400'>Gas fee:</p>
                                            <p className='text-gray-600 dark:text-gray-400'>Transaction fee:</p>

                                            <p className='text-gray-800 pt-2 text-lg dark:text-gray-200'>Total:</p>
                                        </div>
                                        <div className='flex flex-col row-span-1 items-start gap-2 w-1/2'>
                                            <p className='text-gray-600 pt-2 dark:text-gray-400'>{info.price}</p>
                                            <p className='text-gray-600 dark:text-gray-400'>~0.0003</p>
                                            <p className='text-gray-600 dark:text-gray-400'>{info.price * 0.025}</p>

                                            <p className='text-gray-800 dark:text-gray-300 pt-2 text-lg'>~{(info.price * 1.025 +0.0003).toFixed(10)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-center items-center">
                                    <img src={info.image} loading="lazy" alt="Photo by Himanshu Dewangan" className="w-36 h-36 object-cover object-center rounded-xl jusitify-end" />
                                </div>
                            </div>
                            {/*body end*/}

                            {/*footer*/}
                            <div className="flex items-center justify-center p-6 border-t border-solid border-gray-300 dark:border-gray-700 rounded-b gap-2.5">
                                <button
                                className="w-36 bg-teal-500 text-white active:bg-teal-700 font-bold uppercase py-3 text-sm rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => buyHandler(info)}
                                >
                                Buy
                                </button>
                                <button
                                className="w-36 bg-gray-500 text-white active:bg-gray-600 font-bold uppercase text-sm py-3 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => setShowBuyModal(false)}
                                >
                                Cancel
                                </button>
                            </div>
                            {/*footer end*/}

                            </div>

                        )
                    }
                    {/*content end*/}

                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}

export default BuyModal