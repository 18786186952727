
import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import SkeletonPlayer from "../SkeletonLoading/SkeletonPlayer";

import { useParams } from "react-router-dom";

export default function AccountPlayer() {
	const [nfts, setNfts] = useState([]);
	const [loadingState, setLoadingState] = useState("not-loaded");
	const [isLoading, setIsLoading] = useState(false);
    const { account } = useParams();

	async function loadNFTs() {
		setIsLoading(true);
		try {
			// console.log("address in loadNFTs: ", account);
			const items = await fetch(
			`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/games`,
			{
				method: "POST",
				body: JSON.stringify({
				address: account,
				}),
				headers: {
				"Content-Type": "application/json",
				},
			}
			)
			.then((response) => {
				// console.log("response: ", response);
				return response.json();
			})
			.then((data) => {
				// console.log("data.game_list", data.game_list);
				return data.game_list;
			});
			setNfts(items);
			setLoadingState("loaded");
			setIsLoading(false);
		} catch (error) {
			console.log("error: ", error);
		}
	}

    useEffect(() =>{
        loadNFTs();
    },[])

	if (loadingState === 'loaded' && !nfts.length) return (<h1 className="py-10 px-20 text-3xl justify-center min-h-screen">No assets owned</h1>)

	return (
		<div className="flex justify-center pb-4 bg-white dark:bg-gray-800">
			<div className="">
				{isLoading ? (
					<SkeletonPlayer />
				) : (
					<div className="grid xmd:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 pt-4">
						{
							nfts.map((nft, i) => (
								<Link to={`/game/${parseInt(nft.tokenId.hex, 16)}`} key={i} className="group border dark:bg-gray-700 dark:border-gray-600 dark:hover:bg-gray-600 shadow rounded-xl overflow-hidden h-128 w-64">
									<div>
										<div className='w-64 h-64 flex justify-end items-end bg-gray-100 overflow-hidden shadow-lg relative'>
											<img src={nft.image} className='w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200' alt="nft" />
										</div>
									</div>
									<div className="p-4">
										<p className="test-2xl font-semibold dark:text-gray-200">{nft.name}</p>
										<p className="text-1xl font-bold dark:text-gray-400">{nft.amount} owned</p>
										
									</div>
								</Link>
							))
						}
					</div>
				)}
			</div>
		</div>
	)
}