import React from "react";
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import axios from "axios";
import minterContract from "../../contracts/Minter.json";
import gameplaceContract from "../../contracts/GamePlace.json";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { providerOptions } from "../../connect/providerOptions";
import { Wallet, web3Modal } from "../../connect/Wallet";

import { gameplaceAddress } from "../../config";

import LibraryContext from "../../LibraryContext";
import Intro from "../Home/Intro";
import Intro2 from "../Home/Intro2";

import SkeletonPlayer from "../SkeletonLoading/SkeletonPlayer";
import SkeletonSmallPlayer from "../SkeletonLoading/SkeletonSmallPlayer";

export default function Player() {
	const [authToken, setAuthToken] = useState();
	const [nfts, setNfts] = useState([]);
	const [account, setAccount] = useState();
	const [loadingState, setLoadingState] = useState("not-loaded");
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	var currentUrl = window.location.pathname;

	var authrequest;
	const [library, setLibrary] = useContext(LibraryContext);

	async function loadNFTs(address) {
		
		setIsLoading(true);
		// console.log("address in loadNFTs: ", address);
		try {
			const items = await fetch(
			`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/games`,
			{
				method: "POST",
				body: JSON.stringify({
				address: address,
				}),
				headers: {
				"Content-Type": "application/json",
				},
			}
			)
			.then((response) => {
				// console.log("response: ", response);
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}
				return response.json();
			})
			.then((data) => {
				// console.log("data.game_list", data.game_list);
				return data.game_list;
			});
			setNfts(items);
		} catch (error) {
			console.log("error: ", error);
		};
		setLoadingState("loaded");
		setIsLoading(false);
	}

	useEffect(() => {
		var account;
		var auth;
		try {
		account = JSON.parse(localStorage.getItem("account"));
		} catch (e) {
		account = localStorage.getItem("account");
		}
		try {
		auth = JSON.parse(localStorage.getItem("authToken"));
		} catch (e) {
		auth = localStorage.getItem("authToken");
		}
		setAuthToken(auth);
		setAccount(account);
		loadNFTs(account);
	}, []);

	const playGameHandler = async (i) => {
		try {
			await fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/games/play`, {
			body: JSON.stringify({
				public_address: `${account}`,
				session_token: `${authToken}`,
			}),
			headers: {
				"Content-Type": "application/json",
			},
			method: "POST",
			})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				localStorage.setItem("gamingToken", JSON.stringify(data.token));
			});

			await fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/getGameId`, {
				body: JSON.stringify({ token_id: `${i}` }),
				headers: {
					"Content-Type": "application/json",
				},
				method: "POST",
			})
			.then((response) => {
				if (!response.ok) {
					navigate(`/it's not ok`);
				}
				return response.json();
			})
			.then((data) => {
				navigate(`/gaming/${data.game_id}`);
			});
			
		} catch (error) {
			console.log("error: ", error);
		}
	};

	const slideLeft = () => {
		var slider = document.getElementById('slider');
		slider.scrollLeft = slider.scrollLeft - 500;
	};

	const slideRight = () => {
		var slider = document.getElementById('slider');
		slider.scrollLeft = slider.scrollLeft + 500;
	};

	if (loadingState === 'loaded' && !nfts.length && currentUrl != '/') return (<h1 className="py-10 px-20 text-3xl justify-center min-h-screen">No assets owned</h1>)

	if (isLoading == true && currentUrl == '/') return (
		<SkeletonSmallPlayer/>
	)

	if (currentUrl == '/') {
		if (loadingState === 'loaded' && nfts.length) {    
		return(
			<div className='max-w-screen-2xl px-4 md:px-8 mx-auto py-6 sm:pt-12'>
				<div className="bg-gray-100 dark:bg-gray-700 rounded-lg p-4 sm:p-8">
					<div className="">
						<h2 className="text-gray-800 dark:text-gray-200 text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-6">Your Library</h2>
					</div>
					<div className='relative flex items-center max-w-screen-2xl sm:mx-[-20px]'>
						{/* <button className='opacity-50 cursor-pointer hover:opacity-100 h-10 w-10 pr-5' onClick={slideLeft} >
							<img src='/images/left-arrow.png' className='dark:hidden' />
							<img src='/images/left-arrow-white.png' className='hidden dark:block' />
						</button> */}
						<button className='opacity-50 cursor-pointer hover:opacity-100 h-10 w-10 bg-gray-200 dark:bg-gray-500 border-collapse rounded-full p-2 pr-2.5 mr-[-18px] z-20 hidden sm:flex justify-center items-center' onClick={slideLeft} >
							<img src='/images/left-arrow.png' className='dark:hidden h-4 w-4' />
							<img src='/images/left-arrow-white.png' className='hidden dark:block h-4 w-4' />
						</button>
						<div
							id='slider'
							className='w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide'
						>
							{nfts.map((nft, i) => (
								<button key={i} onClick={function(){playGameHandler(parseInt(nft.tokenId.hex, 16))}} className="p-2 ">
									<div className="inline-block border dark:border-gray-700 shadow rounded-xl overflow-hidden h-72 w-72 hover:scale-105">
										<div className='w-72 h-72'>
											<img src={nft.image} className='w-full h-full object-cover object-center' alt="nft" />
										</div>
									</div>
								</button>
							))}
						</div>
						{/* <button className='opacity-50 cursor-pointer hover:opacity-100 h-10 w-10 pl-5' onClick={slideRight} >
							<img src='/images/right-arrow.png' className='dark:hidden' />
							<img src='/images/right-arrow-white.png' className='dark:block hidden' />
						</button> */}
						<button className='opacity-50 cursor-pointer hover:opacity-100 h-10 w-10 bg-gray-200 dark:bg-gray-500 border-collapse rounded-full p-2 pl-2.5 ml-[-18px] z-20 hidden sm:flex justify-center items-center' onClick={slideRight} >
							<img src='/images/right-arrow.png' className='dark:hidden h-4 w-4' />
							<img src='/images/right-arrow-white.png' className='dark:block hidden h-4 w-4' />
						</button>
					</div>
				</div>
			</div>
		)
		} else {
			return (
				<>
					<Intro />
					<Intro2 />
				</>
			)
		}
	}


	return (
		<div className="flex justify-center pb-4 bg-white dark:bg-gray-800">
			<div className="">
				{isLoading ? (
					<SkeletonPlayer />
				) : (
					<div className="grid xmd:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 pt-4">
						{
							nfts.map((nft, i) => (
								<div key={i} className="group border dark:bg-gray-700 dark:border-gray-600 dark:hover:bg-gray-600 shadow rounded-xl overflow-hidden h-128 w-64">
									<Link to={`/game/${parseInt(nft.tokenId.hex, 16)}`}>
										<div className='w-64 h-64 flex justify-end items-end bg-gray-100 overflow-hidden shadow-lg relative'>
											<img src={nft.image} className='w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200' alt="nft" />
										</div>
									</Link>
									<div className="p-4">
										<p className="test-2xl font-semibold dark:text-gray-200">{nft.name}</p>
										<p className="text-1xl font-bold dark:text-gray-400">{nft.amount} owned</p>
										<button className="mt-4 w-full bg-teal-500 text-white font-bold py-2 px-12 rounded hover:bg-teal-400" onClick={function(){playGameHandler(parseInt(nft.tokenId.hex, 16))}}>Play!</button>
									</div>
								</div>
							))
						}
					</div>
				)}
			</div>
		</div>
	)
}