import React from 'react'

function SkeletonReviews() {
    return (
        <div className="bg-white dark:bg-gray-800 py-6 sm:py-8 lg:py-12">
            <div className="max-w-screen-lg px-4 md:px-8 mx-auto animate-pulse">
                <div className='w-1/3 h-[36px] mx-auto bg-gray-200 rounded-full dark:bg-gray-600 mb-4 md:mb-8 xl:mb-12'></div>
                <div className="flex justify-between items-center border-t border-b py-4 mb-4">
                    <div className="flex flex-col gap-0.5">
                        <div className="w-[100px] h-[21px] bg-gray-200 rounded-full dark:bg-gray-600"></div>
                        <div className="w-[100px] h-[25.5px] bg-gray-200 rounded-full dark:bg-gray-600"></div>
                    </div>
                    <div className="w-[173px] h-[50px] bg-gray-200 dark:bg-gray-600 rounded-lg px-4 md:px-8 py-2 md:py-3"></div>
                </div>
                <div className="divide-y">
                    <div className="flex flex-col gap-3 py-4 md:py-8">
                        <div className="w-1/4 h-[40px] bg-gray-200 dark:bg-gray-600 rounded-full"></div>
                        <div className="w-[100px] h-[20px] bg-gray-200 rounded-full dark:bg-gray-600"></div>
                        <div className="w-full h-[21px] bg-gray-200 rounded-full dark:bg-gray-600"></div>
                        <div className="w-[100px] h-[20px] bg-gray-200 rounded-full dark:bg-gray-600"></div>
                    </div>
                    <div className="flex flex-col gap-3 py-4 md:py-8">
                        <div className="w-1/4 h-[40px] bg-gray-200 dark:bg-gray-600 rounded-full"></div>
                        <div className="w-[100px] h-[20px] bg-gray-200 rounded-full dark:bg-gray-600"></div>
                        <div className="w-full h-[21px] bg-gray-200 rounded-full dark:bg-gray-600"></div>
                        <div className="w-[100px] h-[20px] bg-gray-200 rounded-full dark:bg-gray-600"></div>
                    </div>
                    <div className="flex flex-col gap-3 py-4 md:py-8">
                        <div className="w-1/4 h-[40px] bg-gray-200 dark:bg-gray-600 rounded-full"></div>
                        <div className="w-[100px] h-[20px] bg-gray-200 rounded-full dark:bg-gray-600"></div>
                        <div className="w-full h-[21px] bg-gray-200 rounded-full dark:bg-gray-600"></div>
                        <div className="w-[100px] h-[20px] bg-gray-200 rounded-full dark:bg-gray-600"></div>
                    </div>
                    <div className="flex flex-col gap-3 py-4 md:py-8">
                        <div className="w-1/4 h-[40px] bg-gray-200 dark:bg-gray-600 rounded-full"></div>
                        <div className="w-[100px] h-[20px] bg-gray-200 rounded-full dark:bg-gray-600"></div>
                        <div className="w-full h-[21px] bg-gray-200 rounded-full dark:bg-gray-600"></div>
                        <div className="w-[100px] h-[20px] bg-gray-200 rounded-full dark:bg-gray-600"></div>
                    </div>
                    <div className="flex flex-col gap-3 py-4 md:py-8">
                        <div className="w-1/4 h-[40px] bg-gray-200 dark:bg-gray-600 rounded-full"></div>
                        <div className="w-[100px] h-[20px] bg-gray-200 rounded-full dark:bg-gray-600"></div>
                        <div className="w-full h-[21px] bg-gray-200 rounded-full dark:bg-gray-600"></div>
                        <div className="w-[100px] h-[20px] bg-gray-200 rounded-full dark:bg-gray-600"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SkeletonReviews