import React from 'react';
import RatingAndCommentModal from './Popups/RatingAndCommentModal';
import { useState , useEffect , useRef } from 'react';
import { useParams } from 'react-router-dom';
import SkeletonReviews from './SkeletonLoading/SkeletonReviews';

function Reviews( {onGetRating} ) {
    const wrapperRef = useRef(null);
    const [rating, setRating] = useState();
    const [comment, setComment] = useState([]);
    const [showRatingAndCommentModal, setShowRatingAndCommentModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {tokenId} = useParams();

    const updateRating = () => {
        onGetRating();
        getLocalRating();
        getComment();
        setShowRatingAndCommentModal(false);
    }

    const getLocalRating = () => {
        var clientRating;
        try {
            clientRating = JSON.parse(localStorage.getItem(`${tokenId}rating`));
        } catch (e) {
            clientRating = localStorage.getItem(`${tokenId}rating`);
        }
        setRating(clientRating);
    }

    const getComment = () => {
        try {
            setIsLoading(true);
            fetch(
                `${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/games/${tokenId}/getComment`,
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                  method: "GET",
                }
              ).then((response) => {
                return response.json();
              }).then( async (data) => {
                data.comments.sort((b, a) => a.id - b.id);
                var items;
                const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

                items = await Promise.all(data.comments.map(async i => {
                    function convertTZ(date, tzString) {
                        return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
                    }
                    const convertedDate = convertTZ(i.upload_date, timeZone);

                    console.log('convertedDate: ', convertedDate)
                         
                    let item = {
                        comments: i.comments,
                        game_id: i.game_id,
                        rating: i.rating,
                        
                        upload_date: convertedDate.toDateString(),
                        upload_time: convertedDate.toTimeString(),

                        user_id: i.user_id,
                    }
                    return item;
                }))

                setComment(items);
                setIsLoading(false);
              });
        } catch (error) {
            console.log(error);
        }
    }
    
    useEffect(() => {
        getLocalRating();
        getComment();
    }, []);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowRatingAndCommentModal(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    if (isLoading) return <SkeletonReviews/>;

    return (
        <div className="bg-white dark:bg-gray-800 py-6 sm:py-8 lg:py-12">
            <div className="max-w-screen-lg w-11/12 px-4 md:px-8 mx-auto">
                <h2 className="text-gray-800 dark:text-gray-200 text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-8 xl:mb-12">Ratings {"&"} Reviews</h2>

                <div className="flex justify-between items-center border-t border-b py-4 mb-4">
                    <div className="flex flex-col gap-0.5">
                        <span className="block font-bold dark:text-gray-300">Your Reviews</span>

                        {/* star start  */}
                        <div className="star-rating">
                            {[...Array(5)].map((star, index) => {
                                index += 1;
                                return (
                                <span
                                    key={index}
                                    className={index <= rating ? "text-teal-500" : "text-gray-500"}
                                >
                                    <span className="star">&#9733;</span>
                                </span>
                                );
                            })}
                        </div>
                        {/* star end  */}
                        
                        {/* <span className="block text-gray-500 text-sm">Bases on 27 reviews</span> */}
                    </div>

                    <a onClick={() => setShowRatingAndCommentModal(true)} className="cursor-pointer inline-block bg-white hover:bg-gray-100 active:bg-gray-200 focus-visible:ring ring-indigo-300 border text-gray-500 text-sm md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-4 md:px-8 py-2 md:py-3">Write a review</a>
                </div>

                {showRatingAndCommentModal && (<RatingAndCommentModal updateRating={updateRating} wrapperRef={wrapperRef} />)}

                <div className="divide-y">
                    {comment.map((item, index) => (
                        
                        <div key={index} className="flex flex-col gap-3 py-4 md:py-8">

                            <div>
                                <span className="block text-sm font-bold dark:text-gray-300">User {item.user_id}</span>
                                <div className='flex gap-3'>
                                <span className="block text-gray-500 text-sm">{item.upload_date}</span>
                                <span className="block text-gray-500 text-sm">{item.upload_time.split('G')[0]}</span>
                                </div>
                            </div>

                            <div className="flex gap-0.5 -ml-1">
                                {[...Array(5)].map((star, index) => {
                                    index += 1;
                                    return (
                                    <span
                                        key={index}
                                        className={index <= item.rating ? "text-teal-500" : "text-gray-500"}
                                    >
                                        <span className="star">&#9733;</span>
                                    </span>
                                    );
                                })}
                            </div>

                            {item.comments === 'undefined' ? (<p className="text-gray-600 dark:text-gray-300"></p>): (<p className="text-gray-600 dark:text-gray-300">{item.comments}</p>)}

                        </div>
                        
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Reviews