import React from 'react'
import GettingStartedPath from '../components/GettingStartedPath';
import GettingStartedSideMenu from '../components/GettingStartedSideMenu';

export default function What_crypto_wallets_can_I_use_with_6uo_Gameplace() {

    return (
        <div className='flex dark:bg-gray-800 dark:text-gray-200'>

            <GettingStartedSideMenu/>

            <div className='flex flex-col'>
            
                <GettingStartedPath/>

                <div className="m-20 mt-0 inline-block text-lg">

                    <h1 className="text-4xl mt-10 mb-10">
                        <p>What crypto wallets can I use with 6uo Gameplace?</p>
                    </h1>

                    <p className="my-8">
                        6uo Gameplace users can sign in with a variety of wallet providers. Although all wallet has unique features, many users prefer MetaMask for desktop use and Coinbase Wallet for mobile use. Please see the table below for our available wallets. By clicking on the name of each wallet, you can learn more about it.
                    </p>

                    <p>Login via:</p>

                    <p className="my-8">
                        &emsp; Browser extention: <a className='text-teal-500 hover:text-teal-600' href='https://metamask.io/download/'>MetaMask</a>, <a className='text-teal-500 hover:text-teal-600' href='https://www.coinbase.com/wallet/downloads'>Coinbase Wallet</a>
                    </p>

                    <p className="my-8">
                        &emsp; QR-code: <a className='text-teal-500 hover:text-teal-600' href='https://www.coinbase.com/wallet/downloads'>Coinbase Wallet</a> and wallets with <a className='text-teal-500 hover:text-teal-600' href='https://walletconnect.com/'>WalletConnect</a>
                    </p>
                    
                    <p className="my-8">
                        You can install them on Chrome Web Store for chromium based browsers or via App Store or Google Play for mobile.
                    </p>
                </div>
        
            </div>

        </div>
    )
}
