import React from 'react';
import { useLocation , useSearchParams } from 'react-router-dom';
import AccountSeller from './OtherAccountTabs/AccountSeller';
import AccountPlayer from './OtherAccountTabs/AccountPlayer';

function ProfileTab() {
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const currentTab = query.get("tab");

    const [tabState, setTabState] = useSearchParams();

    const handleTabChange = (tabOnClick) => {
        const tab = tabOnClick;

        if (tab) {
            setTabState({ tab });
        } else {
            tabState({});
        }
    };

    const renderTab = () => {
        switch (currentTab) {
            // case "ActiveListings":
            //     return <AccountSeller />;
            case "OwnedItems":
                return <AccountPlayer />;
            default:
                return <AccountPlayer />;
        }
    }

    return (
        <div>
            <button onClick={() => handleTabChange("OwnedItems")} className={(currentTab !== "ActiveListings") ? "m-3 text-teal-500 text-lg" : "m-3 dark:text-gray-200 text-lg hover:scale-105"}>Owned Items</button>
            {/* <button onClick={() => handleTabChange("ActiveListings")} className={(currentTab == "ActiveListings") ? "m-3 text-teal-500 text-lg" : "m-3 dark:text-gray-200 text-lg hover:scale-105"}>Active Listings</button> */}
            {/* <a href="/test?tab=Favorited" className="mr-5 dark:text-gray-200">Favorited</a> */}
            <div className="py-10 border-t border-gray-300 text-center">
                <div className="flex flex-wrap justify-center">
                    <div className="w-full px-4">
                        {renderTab()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileTab