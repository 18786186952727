import React , { useState , useRef , useEffect } from 'react';
import { ethers } from 'ethers';
import { gameplaceAddress } from '../../config';
import gameplaceContract from '../../contracts/GamePlace.json';

function EditModal( { nft , library , close } ) {
    const [formInput, updateFormInput] = useState()
    const wrapperRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        function handleClickOutside(event) {
          if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            close();
          }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [wrapperRef]);

    const editHandler = async() => {
        try {
            setIsLoading(true);
            const signer = library.getSigner()
        
            const gameplacecontract = new ethers.Contract(gameplaceAddress, gameplaceContract.abi, signer)
            const newPrice = ethers.utils.parseUnits(formInput, 'ether')
            console.log('New Price: ' , newPrice)
            console.log('Initialize Edit')
            const edit = await gameplacecontract.editListedSale(nft.ID, newPrice)
            console.log('editing... plz wait...')
            await edit.wait()
            setIsLoading(false);
            console.log('edited')
            close()

        } catch (error) {
            console.log(error)
        }
      }
    return (
        <>
            <div className='justify-center items-end sm:items-center m-3 sm:m-0 flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
                <div className="relative sm:my-8 w-full sm:max-w-2xl">
                    {/* content start */}
                    <div ref={wrapperRef} className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white dark:bg-gray-800 outline-none focus:outline-none">

                        {/*header*/}
                        <div className="flex flex-col items-center justify-center p-5 border-solid border-gray-300 dark:border-gray-700 rounded-t">
                            <h3 className="text-2xl font-semibold dark:text-gray-200 px-5">What price do you want to change?</h3>
                        </div>
                        {/*header end*/}

                        {/*body*/}
                        <div className='flex flex-col justify-center items-center'>
                            <p className='mt-6 dark:text-gray-200'>Your old price: {nft.price}</p>
                            <h3 className='text-lg mt-3 dark:text-gray-200'>New Price:</h3>
                            <input
                            placeholder="Price in ETH"
                            className="mb-4 border rounded p-2 text-xl mx-2 text-center"
                            onChange={e => updateFormInput(e.target.value)}
                            />
                        </div>
                        {/*body end*/}

                        {/*footer*/}
                        <div className="flex items-center justify-center p-6 border-solid border-gray-300 dark:border-gray-700 rounded-b gap-2.5">
                            <button className='w-36 bg-teal-500 active:bg-teal-600 text-white font-bold uppercase text-sm py-3 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150' onClick={() => editHandler()}>Edit</button>
                            <button
                            className="w-36 bg-gray-500 text-white active:bg-gray-600 font-bold uppercase text-sm py-3 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() => close()}
                            >
                                Cancel
                            </button>
                        </div>
                        {/*footer end*/}
                    </div>
                    {/*content end*/}
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}

export default EditModal