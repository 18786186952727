import React from 'react'
import GettingStartedPath from '../components/GettingStartedPath';
import GettingStartedSideMenu from '../components/GettingStartedSideMenu';

export default function What_are_the_key_terms_to_understand_when_it_comes_to_NFTs_and_Web3() {
    const queryString = window.location.search;
    console.log(queryString)
    return (
        <div className='flex dark:bg-gray-800 dark:text-gray-200'>
            
            <GettingStartedSideMenu/>

            <div className='flex flex-col'>

            
                <GettingStartedPath/>

                <div className="m-20 mt-0 inline-block text-lg">

                    <h1 className="text-4xl mt-10 mb-10">
                        <p>What are the key terms to understand when it comes to NFTs and Web3?</p>
                    </h1>

                    <p className='font-bold'>NFTs/SFTs</p>
                    <p>
                        Non-Fungible Tokens (NFTs) / Semi-Fungible Tokens (SFTs) are unique digital assets or a unique group of digital assets managed by the blockchain. They validate digital assets such as artwork, collectables, music, videos, in-game items, and games!
                    </p>

                    <p className='mt-10 font-bold'>Blockchain</p>
                    <p>
                        A blockchain is a decentralized, distributed, and public digital ledger used to record transactions across multiple computers and log a massive record of who owns what and how much so that the record cannot be changed retroactively without the modification of all subsequent blocks and the consensus of the network.
                    </p>
                    
                    <p className='mt-10 font-bold'>Gas fees</p>
                    <p>
                        Gas fees are the payments necessary to execute a blockchain transaction. They are often paid directly to the miners or staking parties confirming the blockchain transaction in the blockchain's native currency. Gas fees are not fixed and rely heavily on the availability/consensus model of the network.
                    </p>

                    <p className='mt-10 font-bold'>Crypto Wallet</p>
                    <p>
                        Crypto wallets hold your private keys, securing and making your crypto and NFTs accessible. In addition, they let you transmit, receive, spend and store your crypto assets. 
                    </p>

                    <p className='mt-10 font-bold'>Wallet address</p>
                    <p>
                        A wallet address is a string of letters and digits that may receive or send cryptocurrencies or NFTs. A wallet address is sometimes referred to as a public key and may be shared with several contacts, similar to an email address.
                    </p>

                    <p className='mt-10 font-bold'>Private key</p>
                    <p>
                        A private key is a string of letters and numbers that, like a password, allows you to access and manage your cryptocurrency funds.
                    </p>

                    <p className='mt-10 font-bold'>Seed phrase</p>
                    <p>
                        A seed phrase is a string of words generated by your wallet that allows you to access your crypto assets regardless of what happens to the wallet itself. This recovery phrase provides access not just to your wallet but also to the crypto assets stored inside it.
                    </p>

                    <p className='mt-10 font-bold'>Smart contract</p>
                    <p>
                        Smart contracts are essentially programs that execute when certain criteria are satisfied and are recorded on a blockchain. They are often used to automate the implementation of an agreement so that all parties may be confident of the conclusion instantly, without the participation of an intermediary or time lost. They may also automate a process by automatically activating the next activity when certain circumstances are satisfied.
                    </p>

                    <p className='mt-10 font-bold'>dApp</p>
                    <p>
                        A decentralized application (dApp) is an application that may function freely, generally using smart contracts and running on a blockchain. A dApp might be anything from a cryptocurrency wallet to a blockchain game to a cryptocurrency exchange.
                    </p>

                </div>
        
            </div>
        </div>
    )
}
