import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';

export default function Notification( {auth} ) {
    const navigate = useNavigate();

    const [ownerAccount, setOwnerAccount] = useState();
    const [sessionToken, setSessionToken] = useState();

    const is0Ac = useRef(true);
    const is0Au = useRef(true);

    const [notificationList, setNotificationList] = useState([]);

    const getNotification = () => {
        try {
            fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/notification/getNotification`, {
                body: JSON.stringify({
                    owner_address: `${ownerAccount}`,
                    session_token: `${sessionToken}`,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
            })
            .then((response) => {
                return response.json();
            })
            .then( async (data) => {

                data.notification_list.sort((b, a) => a.notification_id - b.notification_id)

                var items;

                const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
                
                items = await Promise.all(data.notification_list.map(async i => {
                    let image;
                    let isNFT;
                    await getProfileImages(i.actor_address).then((link) => {
                        console.log('link: ', link)
                        image = link;
                    });

                    let imgList = ['png', 'jpg', 'jpeg', 'gif'];
                    if (image !== 'null' && image !== null && image !== undefined && image !== 'undefined') {
                        isNFT = imgList.includes(image.split('.')[1]) ? 1 : 2;
                    } else {
                        isNFT = 0;
                    }
                    console.log('isNFT: ', isNFT);
                    function convertTZ(date, tzString) {
                        return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
                    }
                    const convertedDate = convertTZ(i.time, timeZone);

                    console.log('image: ', image);
                    let item = {
                        actor: i.actor,
                        actor_address: i.actor_address,
                        entity_type_id: i.entity_type_id,
                        notification_id: i.notification_id,
                        notifier: i.notifier,
                        notifier_address: i.notifier_address,
                        seen: i.seen,
                        Date: convertedDate.toDateString(),
                        time: convertedDate.toTimeString(),
                        icon: image,
                        isNFT: isNFT,
                    }
                    return item;
                }))

                setNotificationList(items);
            });
        } catch (e) {
            console.log(e);
        }
    }

    const getProfileImages = async (address) => {
            console.log('address: ', address);
            let result = await fetch (`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/profile/${address}/images`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
              })
              .then(res => res.json())
              .then(data => {
                console.log('data: ', data)
                let imgList = ['png', 'jpg', 'jpeg', 'gif'];
                if (data.profile.icon_path !== null && imgList.includes(data.profile.icon_path.split('.')[1])) {
                    var img = `${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/icon/${data.profile.icon_path}`;
                    return img;
                } else {
                    var ipfs = `${data.profile.icon_path}`;
                    return ipfs;
                }
              })
              .catch (err => {
                console.log(err);
                return;
                })
              return result;
    }

    const acceptHandler = (account) => {
        try{
            fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/friends/accept/${account}`, {
                body: JSON.stringify({
                    owner_address: `${ownerAccount}`,
                    session_token: `${sessionToken}`,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
                })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log(data);
                    // getInvitation();
                    getNotification();
                });
        } catch (error) {
            console.log(error);
        }
    }

    const rejectHandler = (account) => {
        console.log("target account: ", account);
        console.log("owner account: ", ownerAccount);
        console.log("session token: ", sessionToken);
        try{
            fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/friends/reject/${account}`, {
                body: JSON.stringify({
                    owner_address: `${ownerAccount}`,
                    session_token: `${sessionToken}`,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
                })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log(data);
                    getNotification();
                });
        } catch (error) {
            console.log(error);
        }
    }

    const onClickNotification = (address) => {
        navigate(`../${address}`);
    }

    const see = (notification_id) => {
        try{
            fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/notification/see`, {
                body: JSON.stringify({
                    owner_address: `${ownerAccount}`,
                    session_token: `${sessionToken}`,
                    notification_id: `${notification_id}`,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
                })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    // console.log(data);
                    getNotification();
                });
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        var account;
        var authToken;
        try {
            account = JSON.parse(localStorage.getItem("account"));
        } catch (e) {
            account = localStorage.getItem("account");
        }
        try {
            authToken = JSON.parse(localStorage.getItem("authToken"));
        } catch (e) {
            authToken = localStorage.getItem("authToken");
        }
        setOwnerAccount(account);
        setSessionToken(authToken);
    },[])

    useEffect(() => {
        if (is0Ac.current) {
            is0Ac.current = false;
            return;
        }
        getNotification();
    },[ownerAccount])

    useEffect(() => {
        if (is0Au.current) {
            is0Au.current = false;
            return;
        }
        if (!auth) {
            navigate("/");
        }
    },[sessionToken])

  return (
    <div className='h-full min-h-[calc(100vh-64px-235px)] flex justify-center items-start'>

    <div className="max-w-3xl w-full overflow-hidden rounded-lg border border-gray-200 dark:border-gray-700 shadow-md m-5">
        <table className="w-full border-collapse text-left text-sm text-gray-500 dark:text-gray-300">
            <thead className="bg-gray-50 dark:bg-gray-700">
                <tr>
                    <th scope="col" className="px-6 py-4 font-medium text-gray-900"></th>
                    <th scope="col" className="px-6 py-4 font-medium text-gray-900 text-center">Type</th>
                    {/* <th scope="col" className="px-6 py-4 font-medium text-gray-900">Role</th>
                    <th scope="col" className="px-6 py-4 font-medium text-gray-900">Team</th> */}
                    <th scope="col" className="px-6 py-4 font-medium text-gray-900"></th>
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 dark:divide-gray-600 border-t border-gray-100 dark:border-gray-700">

                { notificationList.length == 0 ? (<tr className='h-96'></tr>) : 
                notificationList.map((list, i) => (

                    <tr key={i} onClick={() => see(list.notification_id)} className={(list.seen == 0) ? "bg-blue-50 hover:bg-blue-100 dark:bg-gray-800 dark:hover:bg-gray-600" : "dark:bg-gray-600 hover:bg-gray-50 dark:hover:bg-gray-500"}>
                        <th className="flex gap-3 px-6 py-4 font-normal text-gray-900 cursor-pointer" onClick={() => onClickNotification(list.actor_address)}>
                            <div className="relative h-10 w-10 cursor-pointer" onClick={() => onClickNotification(list.actor_address)}>
                                {
                                    (list.isNFT !== 2) ? (
                                        (list.isNFT !== 1) ? (
                                            <div style={{background: `#${list.actor_address.slice(2, 8).toString()}`}} className="relative w-10 h-10 overflow-hidden rounded-full">
                                                <img
                                                    className="h-full w-full rounded-full object-cover object-center"
                                                    src={`/images/chess_icons/${list.actor_address.match(/\d+$/)%5}.svg`}
                                                    alt=""
                                                />
                                            </div>
                                        ):(
                                            <img
                                                className="h-full w-full rounded-full object-cover object-center"
                                                src={list.icon}
                                                alt=""
                                            />
                                        )
                                    ) : (
                                        <img
                                            alt="..."
                                            src={list.icon}
                                            className="w-full h-full object-cover mask mask-hexagon-2"
                                        />
                                    )
                                }
                                <span className="absolute right-0 bottom-0 h-2 w-2 rounded-full bg-green-400 ring ring-white"></span>
                            </div>
                            <div 
                                className="text-sm"
                            >
                                <div className="font-medium text-gray-700 dark:text-gray-300">{list.actor == null ? list.actor_address : list.actor}</div>
                                <div className="text-gray-400 space-x-4">
                                    <span>{list.Date}</span>
                                    <span>{list.time.split('G')[0]}</span>
                                </div>
                            </div>
                        </th>
                        {/* <td className="px-6 py-4">
                            <span
                                className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600"
                            >
                                <span className="h-1.5 w-1.5 rounded-full bg-green-600"></span>
                                Active
                            </span>
                        </td> */}
                        <td className="px-6 py-4 text-center">{list.entity_type_id == 1 ? 'Friend invitation' : ' '}</td>
                        {/* <td className="px-6 py-4">
                            <div className="flex gap-2">
                                <span
                                className="inline-flex items-center gap-1 rounded-full bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600"
                                >
                                Design
                                </span>
                                <span
                                className="inline-flex items-center gap-1 rounded-full bg-indigo-50 px-2 py-1 text-xs font-semibold text-indigo-600"
                                >
                                Product
                                </span>
                                <span
                                className="inline-flex items-center gap-1 rounded-full bg-violet-50 px-2 py-1 text-xs font-semibold text-violet-600"
                                >
                                Develop
                                </span>
                            </div>
                        </td> */}
                        <td className="px-6 py-4">
                            <div className={list.entity_type_id == 1 ? "flex justify-end gap-4" : "hidden"}>
                                <a onClick={() => rejectHandler(list.actor_address)} className='cursor-pointer border border-gray-500 rounded-lg p-2 hover:bg-blue-100 dark:hover:bg-gray-500'>
                                    <img src='YesNo/cross.svg' className='h-4 w-4 hover:scale-110'/>
                                </a>
                                <a onClick={() => acceptHandler(list.actor_address)} className='cursor-pointer border border-gray-500 rounded-lg p-2 hover:bg-blue-100 dark:hover:bg-gray-500'>
                                    <img src='YesNo/check.svg' className='h-4 w-4 hover:scale-110 ' />
                                </a>
                            </div>
                        </td>
                    </tr>

                ))}

            </tbody>
        </table>
    </div>

    </div>
  )
}
