import React from 'react'
import GettingStartedPath from '../components/GettingStartedPath';
import GettingStartedSideMenu from '../components/GettingStartedSideMenu';

export default function How_to_add_Polygon_Network_in_my_wallet() {

    return (
        <div className='flex dark:bg-gray-800 dark:text-gray-200'>
            
            <GettingStartedSideMenu/>

            <div className='flex flex-col'>
            
                <GettingStartedPath/>

                <div className="m-20 mt-0 inline-block text-lg">

                    <h1 className="text-4xl mt-10 mb-10">
                        <p>How to add Polygon Network in my wallet?</p>
                    </h1>

                    <p className="my-8">
                        In some cryptocurrency wallets, the polygon network must be added manually. The link below shows how to configure your wallet for the polygon network.
                    </p>

                    <a href='https://wiki.polygon.technology/docs/develop/metamask/config-polygon-on-metamask/#add-the-polygon-network-manually' className='text-teal-500 hover:text-teal-700'>&emsp; Add Polygon Network</a>

                </div>
        
            </div>

        </div>
    )
}
