import React from 'react';
import { useRef , useState , useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Steps } from 'rsuite';

function WalletModal({connectStatus, setConnectStatus, setWalletModal, auth, library, agreement, onSignInTo6uo, onLoggedInToMetaMask, onLoggedInToMetaMaskByWalletConnect, onLoggendInToCoinbase, onLoggedInToWalletConnect, onLoggedOut}) {
    const wrapperRef = useRef(null);
    const [safari, setSafari] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [showReminder, setShowReminder] = useState(false);
    const [account, setAccount] = useState();
    const [chainId, setChainId] = useState();
    const [currentIndex, setCurrentIndex] = useState(0);
    // const navigate = useNavigate();

    // const connectWallet = async () => {
    //     try {
    //         console.log("Connecting wallet...")
    //         console.log("library: ", library)
    //         const accounts = await library.listAccounts();
    //         const network = await library.getNetwork();
    //         if (accounts) setAccount(accounts[0]);
    //         setChainId(network.chainId);
    //     } catch (error) {
    //         console.log(error);  
    //     }
    // };

    const metamaskHandler = async () => {
        try {
            // if (!isMobile) {
                onLoggedInToMetaMask();
                setShowReminder(true);
            // } else {
            //     console.log("Connecting to MetaMask...")
            //     onLoggedInToMetaMaskByWalletConnect();
            //     console.log("Connected to MetaMask")
            //     setShowReminder(true);
            // }

        } catch (error) {
            console.log(error);
        }
    }

    const coinbaseHandler = async () => {
        try {
            onLoggendInToCoinbase();
            setShowReminder(true);
        } catch (error) {
            console.log(error);
        }
    }

    const walletConnectHandler = async () => {
        try {
            onLoggedInToWalletConnect();
            setShowReminder(true);
        } catch (error) {
            console.log(error);
        }
    }

    const agreeHandler = async () => {
        try {
            onSignInTo6uo();
            setShowReminder(false);
        } catch (error) {
            console.log(error);
        }
    }

    // const disconnectWallet = async () => {
    //     try {
    //         onLoggedOut();
    //         setConnectStatus(0);
    //         setShowReminder(false);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    const images = ['/images/metamask.png', '/images/Opera.png', '/images/Brave_logo.png']

    useEffect(() => {
        const intervalId = setInterval(() => {
            if(currentIndex === images.length - 1) {
                setCurrentIndex(0);
            } 
            else {
                 setCurrentIndex(currentIndex + 1);
            }
        }, 1500);
        return () => clearInterval(intervalId);
    }, [currentIndex]);

    useEffect(() => {
        setAccount(localStorage.getItem('account'))
        setChainId(localStorage.getItem('chainId'))
    }, [])

    useEffect(() => {
        window.localStorage.setItem('account', account)
        window.localStorage.setItem('chainId', chainId)
    }, [account, chainId])

    useEffect( () => {
        if ( auth && library ) {
            setConnectStatus(2);
            setTimeout(() => {
                setWalletModal(false);
            } , 1000);
        }
    }, [ auth ])

    useEffect( () => {
        if ( library && !auth ) {
            setConnectStatus(1);
        }
    }, [ library ])

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                console.log("You clicked outside of me!");
                setWalletModal(false);
                setShowReminder(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    useEffect(() => {
        if (navigator.userAgent.indexOf("Mobile") !== -1) {
            setIsMobile(true);
        }
        if (navigator.userAgent.indexOf('Safari') == 102) {
            setSafari(true)
        }
    }, [])

    return (
        <>             
            <div className='justify-center items-end sm:items-center m-3 sm:m-0 flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
                <div className='relative sm:my-8 w-full sm:max-w-lg'>
                    { showReminder ? (

                        <div ref={wrapperRef} className='border-0 rounded-xl shadow-lg relative flex flex-col w-full h-auto bg-white dark:bg-gray-800 outline-none focus:outline-none'>
                            
                            {/*header start*/}
                            <div className="flex flex-col items-center justify-center p-5 border-b border-solid border-gray-300 dark:border-gray-700 rounded-t">
                                <h3 className="text-2xl font-semibold text-center dark:text-gray-300">
                                    Connect to your wallet
                                </h3>
                            </div>
                            {/*header end*/}
                        
                            <div className='relative p-12 flex justify-center items-center h-[316px]'>
                                <Steps current={connectStatus} vertical>
                                    <Steps.Item title="Connect Wallet" />
                                    <Steps.Item title="Signature Request" />
                                    <Steps.Item title="Done" />
                                </Steps>
                            </div>

                            { agreement == 0 && (
                                <div className="flex flex-col items-center justify-center p-3 border-t border-solid border-gray-300 dark:border-gray-700 rounded-b gap-2.5">
                                    <div className='py-2 items-center'>
                                        <p className='px-8 dark:text-gray-300'>Before sign in with your wallet and using 6uo Game Place, your agree to our <a href='#' className='text-teal-500 hover:text-teal-700 inline'>terms of service</a>.</p>
                                    </div>
                                    <div className='flex flex-row pt-2 items-center gap-5'>
                                        <button
                                            className="w-36 bg-teal-500 text-white active:bg-teal-700 font-bold uppercase py-3 text-sm rounded-xl shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={agreeHandler}
                                        >
                                            Accept
                                        </button>
                                        <button
                                            className="w-36 bg-gray-500 text-white active:bg-gray-600 font-bold uppercase text-sm py-3 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => setShowReminder(false)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            )}
                            
                        </div>
                        
                    ) : (

                        <div ref={wrapperRef} className="border-0 rounded-xl shadow-lg relative flex flex-col w-full h-auto bg-white dark:bg-gray-800 outline-none focus:outline-none">

                            {/*header start*/}
                            <div className="flex flex-col items-center justify-center p-5 border-b border-solid border-gray-300 dark:border-gray-700 rounded-t">
                                <h3 className="text-2xl font-semibold text-center dark:text-gray-300">
                                    Connect to your wallet
                                </h3>
                            </div>
                            {/*header end*/}

                            {/*body*/}
                            <div className="relative pb-10 px-8 pt-6 flex justify-center gap-10">
                                <div className='flex flex-col gap-5'>
                                    <p className='dark:text-gray-400 text-center'>Connect with one of our available wallet providers or create a new one.</p>
                                    {!safari && !isMobile && (
                                        <button onClick={metamaskHandler} className='border border-gray-400 dark:border-gray-600 hover:border-teal-600 font-bold py-2 px-4 rounded-xl sm:mx-auto sm:w-[220px]'>
                                            <div className='flex flex-row gap-2'>
                                                <img src='/images/metamask.png' className='w-8 h-8 inline-block mr-2' />
                                                <p className='flex flex-col justify-center items-center dark:text-gray-300'>MetaMask</p>
                                            </div>
                                        </button>
                                    )}
                                    {isMobile && (
                                        <button onClick={metamaskHandler} className='border border-gray-400 dark:border-gray-600 hover:border-teal-600 font-bold py-2 px-4 rounded-xl sm:mx-auto sm:w-[220px]'>
                                            <div className='flex flex-row gap-2'>
                                                <div className='h-8 w-8 mr-2'>
                                                    <img src={images[currentIndex]} className='h-full inline-block' />
                                                </div>
                                                <p className='flex flex-col justify-center items-center dark:text-gray-300'>Web3 Browser</p>
                                            </div>
                                        </button>
                                    )}
                                    <button onClick={coinbaseHandler} className='border border-gray-400 dark:border-gray-600 hover:border-teal-600 font-bold py-2 px-4 rounded-xl sm:mx-auto sm:w-[220px]'>
                                        <div className='flex flex-row gap-2'>
                                            <img src='/images/coinbasewallet.png' className='w-8 h-8 inline-block mr-2' />
                                            <p className='flex flex-col justify-center items-center dark:text-gray-300'>Coinbase Wallet</p>
                                        </div>
                                    </button>
                                    <button onClick={walletConnectHandler} className='border border-gray-400 dark:border-gray-600 hover:border-teal-600 font-bold py-2 px-4 rounded-xl sm:mx-auto sm:w-[220px]'>
                                        <div className='flex flex-row gap-2'>
                                            <img src='/images/walletconnect.png' className='w-8 h-8 inline-block mr-2' />
                                            <p className='flex flex-col justify-center items-center dark:text-gray-300'>WalletConnect</p>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            {/*body end*/}

                        </div>

                    )}
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}

export default WalletModal