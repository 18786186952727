import React from 'react';
import { Routes , Route } from 'react-router-dom';

import HelpNav from './HelpNav';

import GettingStarted from './gettingStarted/GettingStarted';
import How_to_create_a_6uo_Gameplace_account from './gettingStarted/How_to_create_a_6uo_Gameplace_account';
import What_crypto_wallets_can_I_use_with_6uo_Gameplace from './gettingStarted/What_crypto_wallets_can_I_use_with_6uo_Gameplace';
import What_are_the_key_terms_to_understand_when_it_comes_to_NFTs_and_Web3 from './gettingStarted/What_are_the_key_terms_to_understand_when_it_comes_to_NFTs_and_Web3';
import What_blockchains_are_available_on_6uo_Gameplace from './gettingStarted/What_blockchains_are_available_on_6uo_Gameplace';
import How_do_I_purchase_cryptocurrencies from './gettingStarted/How_do_I_purchase_cryptocurrencies';
import What_are_service_fees_and_royalties from './gettingStarted/What_are_service_fees_and_royalties';
import What_is_a_crypto_wallet from './gettingStarted/What_is_a_crypto_wallet';
import How_to_add_Polygon_Network_in_my_wallet from './gettingStarted/How_to_add_Polygon_Network_in_my_wallet';
import How_can_I_sign_out_and_connect_to_a_different_wallet from './gettingStarted/How_can_I_sign_out_and_connect_to_a_different_wallet';

import Buying from './buying/Buying';
import How_do_I_buy_games from './buying/How_do_I_buy_games';

import Selling from './selling/Selling';
import How_do_I_publish_games_on_6uo_Gameplace from './selling/How_do_I_publish_games_on_6uo_Gameplace';
import How_do_I_sell_games_on_6uo_Gameplace from './selling/How_do_I_sell_games_on_6uo_Gameplace';
import How_do_I_cancel_or_edit_my_listings_prices from './selling/How_do_I_cancel_or_edit_my_listings_prices';

import Creating from './creating/Creating';

import Policies from './policies/Policies';

import FAQ from './faq/FAQ';

export default function HelpHome() {
    
  return (
    <Routes>
        <Route path="/" element={<HelpNav />} />

        <Route path='Getting_Started' element={<GettingStarted/>} ></Route>
        <Route path='Getting_Started/How_to_create_a_6uo_Gameplace_account' element={<How_to_create_a_6uo_Gameplace_account/>} />
        <Route path='Getting_Started/What_crypto_wallets_can_I_use_with_6uo_Gameplace' element={<What_crypto_wallets_can_I_use_with_6uo_Gameplace/>} />
        <Route path='Getting_Started/What_are_the_key_terms_to_understand_when_it_comes_to_NFTs_and_Web3' element={<What_are_the_key_terms_to_understand_when_it_comes_to_NFTs_and_Web3/>} />
        <Route path='Getting_Started/What_blockchains_are_available_on_6uo_Gameplace' element={<What_blockchains_are_available_on_6uo_Gameplace/>} />
        <Route path='Getting_Started/How_do_I_purchase_cryptocurrencies' element={<How_do_I_purchase_cryptocurrencies/>} />
        <Route path='Getting_Started/What_are_service_fees_and_royalties' element={<What_are_service_fees_and_royalties/>}/>
        <Route path='Getting_Started/What_is_a_crypto_wallet' element={<What_is_a_crypto_wallet/>} />
        <Route path='Getting_Started/How_to_add_Polygon_Network_in_my_wallet' element={<How_to_add_Polygon_Network_in_my_wallet/>} />
        <Route path='Getting_Started/How_can_I_sign_out_and_connect_to_a_different_wallet' element={<How_can_I_sign_out_and_connect_to_a_different_wallet/>} />

        <Route path='Buying' element={<Buying/>}></Route>
        <Route path='Buying/How_do_I_buy_games' element={<How_do_I_buy_games/>} />

        <Route path='Creating' element={<Creating/>}>
            {/* <Route path=':game_id' element={<Gaming/> } /> */}
        </Route>

        <Route path='FAQ' element={<FAQ/>}>
            {/* <Route path=':game_id' element={<Gaming/> } /> */}
        </Route>

        <Route path='Policies' element={<Policies/>}>
            {/* <Route path=':game_id' element={<Gaming/> } /> */}
        </Route>

        <Route path='Selling' element={<Selling/>}></Route>
        <Route path='Selling/How_do_I_publish_games_on_6uo_Gameplace' element={<How_do_I_publish_games_on_6uo_Gameplace/>} />
        <Route path='Selling/How_do_I_sell_games_on_6uo_Gameplace' element={<How_do_I_sell_games_on_6uo_Gameplace/>} />
        <Route path='Selling/How_do_I_cancel_or_edit_my_listings_prices' element={<How_do_I_cancel_or_edit_my_listings_prices/>} />

        {/* <Route path="*" element={<NotFound/>} /> */}
    </Routes>

  )
}
