import React from 'react'
import GettingStartedPath from '../components/GettingStartedPath';
import GettingStartedSideMenu from '../components/GettingStartedSideMenu';

export default function How_can_I_sign_out_and_connect_to_a_different_wallet() {

    return (
        <div className='flex dark:bg-gray-800 dark:text-gray-200'>

            
            <GettingStartedSideMenu/>

            <div className='flex flex-col'>

            <GettingStartedPath/>

            <div className="m-20 mt-0 inline-block text-lg">

                <h1 className="text-4xl mt-10 mb-10">
                    <p>How can I sign out and connect to a different wallet?</p>
                </h1>

                <p className="my-8">
                    Want to log out of your account on 6uo Gameplace? Desire to switch accounts/wallets? This article will provide step-by-step instructions.
                </p>

                <p>Sign out your current account:</p>

                <p>
                    &emsp; 1. Click the Profile button at the top right of the page.
                </p>

                <p><img className="my-20 width-full mx-auto" src="/HelpCenter/How_can_I_sign_out_and_connect_to_a_different_wallet/clickProfile.gif"></img></p>

                <p>
                    &emsp; 2. From the dropdown menu, select Sign out.
                </p>

                <p><img className="my-20 width-full mx-auto" src="/HelpCenter/How_can_I_sign_out_and_connect_to_a_different_wallet/signOut.gif"></img></p>
                
                <p>Connect to a new wallet again:</p>

                <p>
                    &emsp; 3. Click the Wallet button at the top right of the page.
                </p>
                
                <p><img className="my-20 width-full mx-auto" src="/HelpCenter/How_can_I_sign_out_and_connect_to_a_different_wallet/clickWallet.gif"></img></p>

                <p>
                    &emsp; 4. Now, you can choose your way to connect to your new wallets/accounts.
                </p>

                <p><img className="my-20 width-full mx-auto" src="/HelpCenter/How_can_I_sign_out_and_connect_to_a_different_wallet/chooseWallet.gif"></img></p>

            </div>
        
            </div>
        </div>
    )
}
