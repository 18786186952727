import WalletConnect from "@walletconnect/web3-provider"
import CoinbaseWalletSDK from "@coinbase/wallet-sdk"

const INFURA_KEY = '1217ddcd8a8845a69be2994967fd392e'

export const providerOptions = {
    walletlink: {
        package: CoinbaseWalletSDK, // Required
        options: {
            appName: "Web 3 Modal Demo", // Required
            infuraId: INFURA_KEY // Required unless you provide a JSON RPC url; see `rpc` below
        }
    },
    walletconnect: {
        package: WalletConnect, // required
        options: {
            infuraId: INFURA_KEY // required
        }
    },
};