import React, { useContext , Component , useRef } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { ethers, FixedNumber } from 'ethers';
import { web3Modal } from "../connect/Wallet";
import minterContract from '../contracts/Minter.json';
import gameplaceContract from '../contracts/GamePlace.json';
import { gameplaceAddress, sepoliaProvider } from '../config';
import { useState, useEffect } from 'react';
import { truncateAddress } from "../connect/utils"
import { useNavigate } from "react-router-dom";
import BuyModal from '../components/Popups/BuyModal';
import SellModal from '../components/Popups/SellModal';
import BuyListingModal from '../components/Popups/BuyListingModal';
import CancelListingsModal from '../components/Popups/CancelListingsModal';
import Reviews from '../components/Reviews';
import SkeletonGame from '../components/SkeletonLoading/SkeletonGame';
import { info } from 'autoprefixer';
import { checkResultErrors, parseUnits } from 'ethers/lib/utils';

const LS_KEY = '6uo-game-place';

function Game({ auth , setWalletModal}) {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingState, setLoadingState] = useState('not-loaded');
  const {tokenId} = useParams();
  const [information, setInformation] = useState([]);
  const [trading, setTrading] = useState([]);
  const [ownInfo, setOwnInfo] = useState([]);
  const [provider, setProvider] = useState(null);
  const [library, setLibrary] = useState();
  const [account, setAccount] = useState();
  const [chainId, setChainId] = useState();
  const [error, setError] = useState();
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [showSellModal, setShowSellModal] = useState(false);
  const [showListModal, setShowListModal] = useState(null);
  const wrapperRef = useRef(null);
  const [loadingModal, setLoadingModal] = useState(0);
  const [ownListings, setOwnListings] = useState([]);
  const navigate = useNavigate();
  const [amountLeft, setAmountLeft] = useState();
  const [showCancelModal, setShowCancelModal] = useState(null);
  
  const [rating, setRating] = useState(0);
  const [ratingCount, setRatingCount] = useState(0);

  const [ratingInput, setRatingInput] = useState();
  const [commentInput, setCommentInput] = useState();
  const [gameImage, setGameImage] = useState();

  const [isFavorite, setIsFavorite] = useState(null);

  const [image, setImage] = useState(0);

  const scroller = React.createRef()

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowBuyModal(false);
        setShowSellModal(false);
        setShowListModal(null);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const loadGameInfo = async () => {
    var result;
    try {
      await fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/games/${tokenId}/info`)
        .then((response) => {
          if (response.status !== 200) {
            throw new Error("HTTP error " + response.status);
          }
          return response.json();
        })
        .then((data) => {
          result = data.game_info;
        });
      return result;
    } catch (error) {
      console.log(error);
    };
  }

  const loadNFT = async () => {
    try {
      setIsLoading(true);
      const providers = new ethers.providers.JsonRpcProvider(sepoliaProvider);

      const gameplacecontract = new ethers.Contract(gameplaceAddress, gameplaceContract.abi, providers);

      const detail = await gameplacecontract.getGameDetail( tokenId );
      const data = await gameplacecontract.tokenIdOnSale( tokenId );
      console.log(detail);
      const mintercontract = new ethers.Contract(detail[0].mintContract, minterContract.abi, providers);
      const tokenUri = await mintercontract.uri(tokenId);
      const meta = await axios.get(tokenUri);
      const image = meta.data.image.replace('ipfs:/', 'https://ipfs.io/ipfs/');
      var tmp = await loadGameInfo();

      var gallery_tmp = JSON.parse(tmp.gallery);
      var gallery_arr = [];
      for (var i = 0; i < gallery_tmp.length; i++){
        gallery_arr.push(gallery_tmp[i]);
        gallery_arr[i].full_path = `${process.env.REACT_APP_BACKEND_RESOURCE_URL}/${gallery_arr[i].full_path}`;
      }
      setGameImage(gallery_arr);
      const infos = await Promise.all(detail.map(async i => {

        let price = FixedNumber.fromValue(i.price, 18).toString();
        let info = {
          id: i.id,
          name: tmp.name,
          image: `https://sandbox.6uogames.com/${tmp.icon}`,
          description: tmp.description,
          creator: i.creator,
          price: price,
          amountLeft: i.amountLeft.toNumber(),
          totalAmount: i.totalAmount.toNumber(),
          releases: tmp.releases_date.replace(/T\d+\:\d+\:\d+.\d+Z/,''),
          platforms: tmp.platforms,
          category: tmp.category,
          gallery: gameImage
        };
        
        document.title = `${info.name} | 6uo`;
        setAmountLeft(i.amountLeft.toNumber());
        return info;
      }))

      const trades = await Promise.all(
        data
          .map(
            async t => {
              let price = ethers.utils.formatUnits(t.price.toString(), 'ether')
              let trade = {
                id: t.id,
                price: price,
                seller: t.seller
              }
              return trade
            }
          )
      )

      const lists = trades.sort((a, b) => a.price - b.price);
      const listings = lists.filter(list => {
        return list.price !== "0.0"
      });
      setInformation(infos)
      setTrading(listings)
      setLoadingState('loaded') 
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const getBalance = async () => {
    try {
      const provider = await web3Modal.connect()
      const library = new ethers.providers.Web3Provider(provider)
      const accounts = await library.listAccounts();
      const network = await library.getNetwork();
      setProvider(provider);
      setLibrary(library);
      if (accounts) setAccount(accounts[0]);
      setChainId(network.chainId);
      const signer = library.getSigner();
      const gameplacecontract = new ethers.Contract(gameplaceAddress, gameplaceContract.abi, signer)
      const getBalance = await gameplacecontract.getBalance();
      const items = await Promise.all(getBalance.map(async i => {
        let item = {
          amount: i.amounts.toNumber(),
          tokenId: i.tokenId,
          onSale: i.onSale,
        }
        return item
      }))
      const ownGameInfo = items.find((g) => g.tokenId == tokenId)
      setOwnInfo(ownGameInfo)
    } catch (error) {
      setError(error)
    }
  }

  const item0 = (trade) => {
    if (trade.seller == 0) {
      return false
    } else {
      return true
    }
  }

  // get rating of this game
  const getRating = async () => {
    try{
      fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/games/${tokenId}/getRating`)
        .then((response) => {
          if (response.status !== 200) {
            throw new Error("HTTP error " + response.status);
          }
          return response.json();
        })
        .then((data) => {
          setRating(data.rating);
          setRatingCount(data.num_of_rating);
        });
    } catch (error) {
      console.log(error);
    }
  }

  const getFavorite = async () => {
    try{
      fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/getFavorite`, {
          body: JSON.stringify({
            token: `${auth}`,
            public_address: `${account}`,
          }),
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
        })
        .then((response) => {
          if (response.status !== 200) {
            throw new Error("HTTP error " + response.status);
          }
          return response.json();
        })
        .then((data) => {
          console.log('favorite', data.game_list);
          const isFavorite = data.game_list.find(obj => obj.tokenId == tokenId);
          setIsFavorite(isFavorite);
          console.log('isFavorite', isFavorite);
        });
    } catch (error) {
      console.log(error);
    }
  }

  const favorite = async () => {
    try {
      console.log('favorite:', 'acoount', account, 'token', auth, 'gameId', tokenId);
      fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/favorite`, {
        body: JSON.stringify({
          token: `${auth}`,
          public_address: `${account}`,
          id: `${tokenId}`,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
          // if (data.status === 'success') {
          //   alert('Added to favorite!');
          // } else {
          //   alert('Failed to add to favorite');
          // }
        });

      getFavorite();
    }
    catch (error) {
      console.log(error);
    }
  }

  const unfavorite = async () => {
    try {
      console.log('unfavorite:', 'acoount', account, 'token', auth, 'gameId', tokenId);
      fetch(`${process.env.REACT_APP_BACKEND_URL}:${process.env.REACT_APP_BACKEND_PORT}/nft/unfavorite`, {
        body: JSON.stringify({
          token: `${auth}`,
          public_address: `${account}`,
          id: `${tokenId}`,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
          // if (data.status === 'success') {
          //   alert('Removed from favorite!');
          // } else {
          //   alert('Failed to remove from favorite');
          // }
        });

      getFavorite();
    }
    catch (error) {
      console.log(error);
    }
  }

  const favoriteHandler = () => {
    if (isFavorite) {
      unfavorite();
    } else {
      favorite();
    }
  }

  const buyHandler = () => {
    if (!auth) {
      // navigate("/wallet");
      setWalletModal(true);
    } else {
      setShowBuyModal(true);
    }
  }

  const own = () => {
    if (!auth) {
      // console.log("own: don't have auth")
      return false
    } else {
      if (ownInfo) {
        if (ownInfo.onSale) {
          // console.log('own: true but it already onSale, see: ', ownInfo.onSale)
          return false
        } else {
          // console.log('own: true because onwInfo: ', ownInfo)
          return true
        }
      } else {
        // console.log('own: false, see ownInfo: ', ownInfo)
        return false
      }
    }
  }

  const handleRightScroll = () => {
    scroller.current.scrollLeft += 625;
  }

  const handleLeftScroll = () => {
    scroller.current.scrollLeft -= 625;
  }

  const close = async () => {
    setShowBuyModal(false)
    setShowSellModal(false)
    setShowListModal(null)
    setShowCancelModal(null)
    setLoadingModal(0)
    await getBalance()
    loadNFT()
  }

  const handleBuy = (e) => {
    if (!auth) {
      // navigate("/wallet");
      setWalletModal(true);
    } else {
      e.preventDefault();
      setShowListModal(e.target.id);
    }
  }

  const handleCancel = (e) => {
    e.preventDefault();
    setShowCancelModal(e.target.id);
  }

  useEffect(() => {
    loadNFT();
    getRating();
  }, []);

  useEffect(() => {
    if (auth) {
      getBalance();
    }
  }, [auth]);

  useEffect(() => {
    if (account) {
      getFavorite();
    }
  }, [account]);

  useEffect(() => {
    own()
  }, [ownInfo])

  if (isLoading == true) return (
    <SkeletonGame/>
  )

  return (
    <div className='bg-white dark:bg-gray-800 sm:py-8 lg:py-12 min-h-max min-w-[390px]'>
      <div className="max-w-screen-xl mx-auto dark:bg-gray-800">
      {
        information.map((info, index) => (
          <div key={index}>

            {/* game name start */}
            <h2 className="text-gray-800 dark:text-white text-3xl font-bold pb-6 sm:pl-16 hidden lg:block">{info.name}</h2>
            {/* game name end */}

            {/* game detail start */}
            <div className='flex flex-col-reverse lg:flex-row justify-center items-center lg:items-start gap-6 px-4'>

              {/* game image start */}
              <div className='flex flex-col justify-center'>

                <div className="mx-auto h-96 rounded-md hidden lg:block">
                  {
                    gameImage[image].type === "image"? (
                      <img src={gameImage[image].full_path} loading="lazy" alt="Game image" className="h-full object-center rounded-md object-contain" />
                    ):(
                      <video className="h-full object-center rounded-md object-contain"
                      autoPlay
                      controls = {true}
                      src={gameImage[image].full_path}
                      ></video> 
                    )
                  }
                </div>

                <div className='relative flex items-center my-5'>
                  <button className='opacity-50 cursor-pointer hover:opacity-100 h-10 w-10 bg-gray-200 dark:bg-gray-500 border-collapse rounded-full p-2 pr-2.5 mr-[-18px] z-20 hidden sm:flex justify-center items-center' onClick={handleLeftScroll} >
                    <img src='/images/left-arrow.png' className='dark:hidden h-4 w-4' />
                    <img src='/images/left-arrow-white.png' className='hidden dark:block h-4 w-4' />
                  </button>
                  <div
                    ref={scroller}
                    className='w-full h-48 sm:h-72 md:h-96 lg:h-full overflow-x-scroll overflow-y-hidden whitespace-nowrap scroll-smooth container-snap rounded-lg lg:rounded-none lg:shadow-lg'
                  >
                    {gameImage.map((gameImage, index) => (
                      <div key={index} className='inline-grid rounded-md'>

                        {index == image ? (
                          <button onClick={() => setImage(index)} className="mx-1 lg:p-1 h-48 sm:h-72 md:h-96 lg:h-24">
                            {
                              gameImage.type === "image"? (
                                <img src={gameImage.full_path} loading="lazy" alt="Game image" className="h-full object-contain object-center rounded-md lg:outline-none lg:ring-2 lg:ring-teal-500" />
                              ):(
                                <video className='h-full object-contain object-center rounded-md lg:outline-none lg:ring-2 lg:ring-teal-500'
                                src={gameImage.full_path}
                                ></video>
                              )
                            }
                          </button>
                        ) : (
                          <button onClick={() => setImage(index)} className="mx-1 lg:p-1 h-48 sm:h-72 md:h-96 lg:h-24">
                            {
                              gameImage.type === "image"? (
                                <img src={gameImage.full_path} loading="lazy" alt="Game image" className="h-full object-contain object-center rounded-md" />
                              ):(
                                <video className='h-full object-contain object-center rounded-md'
                                src={gameImage.full_path}
                                ></video>
                              )
                            }
                          </button>
                        )}

                      </div>
                    ))}
                  </div>
                  <button className='opacity-50 cursor-pointer hover:opacity-100 h-10 w-10 bg-gray-200 dark:bg-gray-500 border-collapse rounded-full p-2 pl-2.5 ml-[-18px] z-20 hidden sm:flex justify-center items-center' onClick={handleRightScroll} >
                    <img src='/images/right-arrow.png' className='dark:hidden h-4 w-4' />
                    <img src='/images/right-arrow-white.png' className='dark:block hidden h-4 w-4' />
                  </button>
                </div>

              </div>
              {/* game image end */}

              {/* info start */}
              <div className="justify-center items-center  flex flex-col sm:flex-row lg:flex-col lg:justify-between gap-3 sm:gap-0 lg:gap-1 lg:dark:bg-gray-700 lg:bg-gray-200 rounded-xl lg:h-[501.5px] lg:w-[338px] sm:w-11/12">
                
                {/* logo start */}
                <div className='bg-gray-100 dark:bg-gray-600 rounded-xl overflow-hidden lg:h-[314px] lg:w-[314px] mb-3 lg:mb-0 mt-3 mx-3 sm:max-w-xs'>
                  <img src={info.image} loading="lazy" alt="Game Icon" className="w-full h-full object-cover object-center aspect-square" />
                </div>
                {/* logo end */}
                
                {/* text start */}
                <div className='flex flex-col mx-5 justify-between items-start lg:w-full lg:px-8'>
                  <h2 className="text-gray-800 dark:text-white text-3xl font-bold block lg:hidden">{info.name}</h2>
                  
                  {/* rating start */}
                  <div className='flex justify-between items-center w-full'>
                    <div className="flex items-center gap-3 ml-2 my-2 mb-3">
                      <div className="h-7 flex items-center bg-teal-500 text-white rounded-full gap-1 px-2">
                        <span className="font-bold pl-0.5">
                          {
                            parseInt(rating).toFixed(1) == "NaN" ? '--' : parseInt(rating).toFixed(1)
                          }
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      </div>
                      <span className="text-gray-500 text-sm transition duration-100">{ratingCount} ratings</span>
                    </div>
                    <button onClick={() => favoriteHandler()} className="rounded-full bg-teal-400 h-7 my-2 mb-3 px-3">
                      <div className='h-5 w-5 flex justify-center items-center'>
                        {
                          isFavorite ? (
                            <svg xmlns="http://www.w3.org/2000/svg" id="Filled" viewBox="0 0 24 24" width="20" height="20"><path fill='white' d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z"/></svg>
                          ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="20" height="20"><path fill='white' d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z"/></svg>
                          )
                        }
                      </div>
                    </button>
                  </div>
                  {/* rating end */}

                  {/* description start */}
                  <div className="mb-2 h-[40.5px] overflow-y-scroll scroll scrollbar-hide">
                    <p className="text-gray-500 text-sm leading-none dark:text-gray-300">
                      {info.description}
                    </p>
                  </div>
                  {/* description end */}

                  {/* Releases date start*/}
                  <div className="mb-0.5">
                    <span className="text-gray-500 text-sm transition duration-100 dark:text-gray-300">Releases Date: {info.releases}</span>
                  </div>
                  {/* Releases date end*/}

                  {/* Platforms start */}
                  <div className="mb-0.5">
                    <span className="text-gray-500 text-sm transition duration-100 dark:text-gray-300">Platforms: {info.platforms}</span>
                  </div>
                  {/* Platforms end */}

                  {/* Category start */}
                  <div className="mb-0.5 lg:mb-3">
                    <span className="text-gray-500 text-sm transition duration-100 dark:text-gray-300">Category: {info.category}</span>
                  </div>
                  {/* Category end */}

                </div>
                {/* text end */}

              </div>
              {/* info end */}

            </div>
            {/* game info end */}
            
            {/* new buy and sell start */}
            <div className='dark:bg-gray-700 bg-gray-200 max-w-screen-lg mx-auto w-11/12 rounded-xl mt-8'>

              {/* creator start */}
              <div className='pt-4 px-2.5 max-w-screen-lg w-11/12 mx-auto'>
                <p className="text-gray-500 text-sm truncate dark:text-gray-300">Publisher: {info.creator}</p>
              </div>
              {/* creator end */}
              
              {/* price and button start */}
              <div className="grid grid-cols-1 xmd:grid-cols-2 xmd:gap-10 items-center xmd:justify-between max-w-screen-lg w-11/12 mx-auto">
                
                {/* price start */}
                <div className="cols-span-1 gap-4 flex justify-start px-2.5">
                  <div className="my-2 xmd:my-4">
                    <div className="flex items-end gap-2">
                      <span className="text-gray-800 text-2xl dark:text-white font-bold">{info.price}</span>
                      <span className="text-gray-500 text-sm mb-0.5 truncate"> ETH + Gas fee </span>
                    </div>
                    {(trading.length !== 0) && (trading[0].price < info.price) && (<span className="text-red-500">You also can buy it for {trading[0].price} below</span>)}
                  </div>
                </div>
                {/* price end */}

                {/* button start */}
                <div className="flex justify-end items-center pt-4 xmd:pt-0 pb-4 xmd:pb-0 xmd:justify-end gap-6 px-2.5">
                  <>
                    <button
                      className="w-40 bg-teal-500 hover:bg-teal-700 active:bg-teal-700 focus-visible:ring ring-teal-300 text-white text-sm md:text-base font-semibold text-center rounded-xl px-8 py-3"
                      type='button'
                      onClick={buyHandler}
                    >
                      Buy
                    </button>
                    {showBuyModal ? (
                      <BuyModal loadingModal={loadingModal} setLoadingModal={setLoadingModal} close={close} setShowBuyModal={setShowBuyModal} wrapperRef={wrapperRef} info={info} />
                    ) : null}
                  </>
                  {
                    own() && (
                      <>
                        <button
                          className="w-40 bg-gray-300 hover:bg-gray-400 focus-visible:ring ring-teal-300 text-gray-500 active:text-gray-700 text-sm md:text-base font-semibold text-center rounded-xl px-8 py-3"
                          type='button'
                          onClick={() => {setShowSellModal(true)}}
                        >
                          Sell
                        </button>
                        {showSellModal && (
                          <SellModal loadingModal={loadingModal} setLoadingModal={setLoadingModal} close={close} setShowSellModal={setShowSellModal} wrapperRef={wrapperRef} info={info} tokenId={tokenId} />
                        )}
                      </>
                    )
                  }
                </div>
                {/* button end */}

              </div>
              {/* price and button end */}

            </div>
            {/* new buy and sell end */}

          </div>
        ))
      }
          
      </div>

      {/* {information[0].description && (<div className='max-w-screen-xl px-4 md:px-8 mx-auto dark:text-gray-200 text-xl rounded-xl border-gray-500 pt-6 sm:pt-12'>
        <p>{information[0].description}</p>
      </div>)} */}
      
      <h2 className="text-gray-800 dark:text-gray-200 text-2xl lg:text-3xl font-bold text-center mt-16">Listings</h2>
      {/* listing table start */}
      {trading.length !== 0 ? (
        <table className='table-auto w-11/12 sm:max-w-screen-lg mx-auto my-5 shadow dark:shadow-gray-700 rounded-xl overflow-hidden dark:bg-gray-700 bg-gray-200'>
          <thead className='dark:text-white'>
            <tr>
              <th className='text-lg sm:text-2xl pt-3'>From</th>
              <th className='text-lg sm:text-2xl pt-3'>Price</th>
            </tr>
          </thead>
            <tbody className='overflow-hidden'>
              {
                trading.map(
                  (trade, t) => (
                    item0(trade) && (
                      <tr key={t}>
                        <td className='text-lg sm:text-xl font-mono text-center px-2 dark:text-white lg:table-cell hidden'>{trade.seller}</td>
                        <td className='text-lg sm:text-xl font-mono text-center px-2 dark:text-white lg:hidden table-cell'>{trade.seller ? truncateAddress(trade.seller) : "loading..."}</td>
                        <td className='text-lg sm:text-xl font-mono text-center px-2 dark:text-white'>{trade.price}</td>
                        <td className='text-lg sm:text-xl font-mono text-center w-3/12 px-2 py-2'>
                          {
                            trade.seller !== account ? (
                              <button id={t} type='button' className=" inline-block flex-1 sm:flex-none bg-teal-500 hover:bg-teal-700 active:bg-teal-700 focus-visible:ring ring-teal-300 text-white text-sm md:text-base font-semibold text-center rounded-xl outline-none transition duration-100 px-8 py-3 w-[114px] sm:w-40" onClick={e => handleBuy(e)}>Buy</button>
                            ) : (
                              <button id={t} type='button' className=" inline-block bg-gray-300 hover:bg-gray-400 focus-visible:ring ring-teal-300 text-gray-500 active:text-gray-700 text-sm md:text-base font-semibold text-center rounded-xl outline-none transition duration-100 px-8 py-3 w-[114px] sm:w-40" onClick={e => handleCancel(e)}>Cancel</button>
                            )
                          }
                        </td>
                      </tr>
                    )
                  )
                )
              }
            </tbody>
        </table>
      ) : (
        <div className='text-lg flex justify-center items-center w-11/12 sm:max-w-screen-xl mx-auto my-5 border shadow dark:shadow-gray-700 dark:text-gray-200 h-36 rounded-xl overflow-hidden'>
          No Listings
        </div>
      )}
      {/* listing table end */}
      {
        showListModal != null && (
          <BuyListingModal loadingModal={loadingModal} setLoadingModal={setLoadingModal} close={close} wrapperRef={wrapperRef} information={information} trading={trading} showListModal={showListModal} setShowListModal={setShowListModal} />
        )
      }
      {
        showCancelModal != null && (
          <CancelListingsModal library={library} loadingModal={loadingModal} setLoadingModal={setLoadingModal} close={close} wrapperRef={wrapperRef} information={information} trading={trading} showCancelModal={showCancelModal} setShowCancelModal={setShowCancelModal} />
        )
      }
      <Reviews onGetRating={getRating}/>
    </div>
  )
}

export default Game